import apiClient from "../../../services/api";

export const newGroupApi = ({ name, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {
    name: name,
  };
  return apiClient.post(`/groups/`, data, options);
};

export const updateGroup = ({ id, name, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {
    name: name,
  };
  return apiClient.put(`/groups/${id}`, data, options);
};
