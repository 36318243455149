import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Alert,
  Card,
  CircularProgress,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CheckIcon from "@mui/icons-material/Check";
import logo from "../../assets/logo.png";
import { register } from "./service";

const FormRegister = (props) => {
  const { handleSubmit, error, errorEmail, errorPassword } = props;
  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      noValidate
      sx={{ mt: 1, maxWidth: "400px" }}
    >
      {error && (
        <Alert severity="error">Ops! Não foi se cadastrar no sistema!</Alert>
      )}
      <TextField
        margin="dense"
        required
        fullWidth
        id="name"
        label="Nome"
        name="name"
        autoFocus
      />
      <TextField
        margin="dense"
        required
        fullWidth
        id="email"
        label="Email"
        name="email"
        type="email"
        error={errorEmail}
        helperText={errorEmail && "Email já cadastrado!"}
      />
      <TextField
        margin="dense"
        required
        fullWidth
        name="password"
        label="Senha"
        type="password"
        id="password"
        error={errorPassword}
        helperText={"A senha deve conter mais que 6 caracteres!"}
      />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 2, mb: 1 }}>
        Cadastrar
      </Button>
    </Box>
  );
};

export default function Register() {
  const [error, setError] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const navigate = useNavigate();
  const { state } = useLocation();
  let cardLoginWidth = "35vw";
  if (isXs) {
    cardLoginWidth = "100%";
  } else if (isSM) {
    cardLoginWidth = "50vw";
  }
  let params = useParams();

  useEffect(() => {
    document.body.style.backgroundColor = "#1d365d";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    setErrorPassword(false);
    setErrorEmail(false);
    const data = new FormData(event.currentTarget);
    let email = data.get("email");
    let password = data.get("password");
    let name = data.get("name");
    if (password.length < 6) {
      setErrorPassword(true);
      setLoading(false);
    } else if (!params.group_id || !name || !email || !password) {
      setError(true);
      setLoading(false);
    } else {
      register({
        group_id: params.group_id,
        name: name,
        email: email,
        password: password,
      })
        .then((data) => {
          setRegisterSuccess(true);
          setTimeout(function () {
            navigate(state?.path || "/login");
          }, 3000);
        })
        .catch((err) => {
          if (err.response.status === 409) {
            setErrorEmail(true);
          } else {
            setError(true);
          }
          setLoading(false);
        });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: isXs ? "0" : "5vh",
        }}
      >
        <Card
          sx={{
            width: cardLoginWidth,
            padding: "3vh 5vh 3vh 5vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: "2px 1px 12px 1px",
          }}
        >
          <img
            src={logo}
            style={{ width: "130px", height: "160px", marginRight: "20px" }}
            alt="logo"
          />
          <Typography variant="h5" sx={{ mt: 2 }}>
            LICENÇAS
          </Typography>
          <Typography variant="subtitle1">Registrar</Typography>
          {loading && registerSuccess && (
            <>
              <CheckIcon
                sx={{
                  color: green[500],
                  marginTop: "30px",
                  fontSize: 50,
                }}
              />{" "}
              <Typography
                variant="h6"
                sx={{
                  color: green[500],
                  mt: 1,
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              >
                Cadastro realizado com sucesso! <br /> Por favor aguarde logo
                será redirecionado para o login no sistema!
              </Typography>{" "}
            </>
          )}
          {loading && !registerSuccess && (
            <CircularProgress
              size={48}
              sx={{
                color: green[500],
                marginTop: "30px",
              }}
            />
          )}
          {!loading && (
            <FormRegister
              handleSubmit={handleSubmit}
              error={error}
              errorEmail={errorEmail}
              errorPassword={errorPassword}
            />
          )}
        </Card>
      </Box>
    </Container>
  );
}
