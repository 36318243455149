import React, { useContext, useEffect, useState } from "react";
import { Box, Paper, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import BoxCustom from "../../components/Box";
import DefaultTable from "../../components/Table";
import StoreContext from "../../contexts/Store/StoreContext";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";
import NewUser from "./New";
import { ActionsRow, ActionsToolbar } from "./Actions";
import { Filter } from "./Filter";
import { getUsers } from "./services";

const ChipPermission = (props) => {
  const { PERMISSIONS } = useContext(InterfaceContext);
  const { data } = props;
  return PERMISSIONS[data.permission].chip;
};

const headCells = [
  {
    id: "name",
    numeric: false,
    label: "Nome",
    actions: null,
    sortable: true,
  },
  {
    id: "email",
    numeric: false,
    label: "Email",
    actions: null,
    sortable: true,
  },
  {
    id: "group_name",
    numeric: false,
    label: "Grupo",
    actions: null,
    sortable: true,
  },
  {
    id: "permission",
    numeric: false,
    label: "Permissão",
    actions: ChipPermission,
    sortable: true,
  },
];

const Users = () => {
  const [rows, setRows] = useState([]);
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [needReload, setNeedReload] = useState(true);
  const [openNewUser, setOpenNewUser] = useState(false);
  const { token } = useContext(StoreContext);
  const {
    showMenu,
    setShowErrorAlert,
    setErrorAlertMsg,
    profile,
    setMenuSelected,
  } = useContext(InterfaceContext);
  const theme = useTheme();
  // const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    setMenuSelected(2);
    if (needReload) {
      setLoading(true);
      getUsers({ token: token })
        .then((data) => {
          setRows(data.data);
          setRowsFiltered(data.data)
          setLoading(false);
        })
        .catch((err) => {
          setErrorAlertMsg("Erro em buscar os usuários!");
          setShowErrorAlert(true);
          setLoading(false);
        });
      setNeedReload(false);
    }
  }, [token, needReload, setErrorAlertMsg, setShowErrorAlert, setMenuSelected]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <BoxCustom theme={theme} open={showMenu}>
        <Box sx={{ width: "100%", marginBottom: 4 }}>
          <Paper
            sx={{ padding: 1, marginRight: 1, marginLeft: 1 }}
            elevation={2}
          >
            <Filter
              rows={rows}
              setRows={setRowsFiltered}
              reload={needReload}
              setOpenNewUser={setOpenNewUser}
            />
          </Paper>
        </Box>
        {loading ? (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        ) : (
          <DefaultTable
            rows={rowsFiltered}
            headCells={headCells}
            tableName="Usuários"
            reload={setNeedReload}
            ActionsToolbar={
              ["super_admin", "group_admin"].includes(profile.permission) &&
              ActionsToolbar
            }
            ActionsRow={
              ["super_admin", "group_admin"].includes(profile.permission) &&
              ActionsRow
            }
            defaultOrderBy="name"
            checkBoxEnabled={["super_admin", "group_admin"].includes(
              profile.permission
            )}
          />
        )}
      </BoxCustom>
      <NewUser
        open={openNewUser}
        setOpen={setOpenNewUser}
        reload={setNeedReload}
        type="new"
      />
    </Box>
  );
};

export default Users;
