import React, { useState, useContext, useEffect } from "react";
import { DialogContentText, Button } from "@mui/material";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import DefaultDialog from "../../../components/Dialog";

import { getRegisterLink } from "./services";

const RegisterLink = (props) => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const { open, setOpen, id } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowErrorAlert,
    setErrorAlertMsg,
    setShowSuccessAlert,
    setSuccessAlertMsg,
  } = useContext(InterfaceContext);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setSuccessAlertMsg("Link copiado");
    setShowSuccessAlert(true);
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      getRegisterLink({ id: id, token: token })
        .then((data) => {
          var l = `${window.location.origin}/register/${data.data.register_link}`;
          setLink(l);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setOpen(false);
          setErrorAlertMsg("Erro ao buscar link de registro do grupo!");
          setShowErrorAlert(true);
        });
    }
  }, [
    open,
    id,
    token,
    setLink,
    setLoading,
    setOpen,
    setErrorAlertMsg,
    setShowErrorAlert,
  ]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={"Link para registro de usuários"}
      loading={loading}
    >
      <DialogContentText>
        <span>
          <p>Link para registro de novos usuário:</p>
        </span>
        <span>
          <a href={link}>{link}</a>
        </span>
        <Button color="primary" aria-label="copy" onClick={handleCopy}>
          <CopyAllIcon sx={{ mr: 1 }} /> Copiar Link
        </Button>
      </DialogContentText>
    </DefaultDialog>
  );
};

export default RegisterLink;
