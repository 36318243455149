import apiClient from "../../../services/api";
const { REACT_APP_API_HOST } = process.env;

export const addServer = ({ license_id, data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (REACT_APP_API_HOST.includes("localhost")) {
    data.ignore_default_domain = true;
  }
  return apiClient.post(`/licenses/${license_id}/servers`, data, options);
};

export const updateServer = ({ license_id, server_id, data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (REACT_APP_API_HOST.includes("localhost")) {
    data.ignore_default_domain = true;
  }
  return apiClient.put(`/licenses/${license_id}/servers/${server_id}`, data, options);
};
