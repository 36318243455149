import React, { useContext, useState } from "react";
import axios from "axios";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";
import LinkIcon from "@mui/icons-material/Link";
import EditIcon from "@mui/icons-material/Edit";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import NewGroup from "../New";
import RegisterLink from "./RegisterLink";
import AddLicense from "./AddLicense";
import { deleteGroup, getGroup } from "./services";

export const ActionsRow = (props) => {
  const { row, reload } = props;
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [openAddLicense, setOpenAddLicense] = useState(false);

  const [oldName, setOldName] = useState("");
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    deleteGroup({ id: row.id, token: token })
      .then((data) => {
        setSuccessAlertMsg("Grupo removido com sucesso!");
        setShowSuccessAlert(true);
        reload(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em remover o grupo!");
        setShowErrorAlert(true);
      });
  };

  const handleUpdate = () => {
    getGroup({ id: row.id, token: token })
      .then((data) => {
        setOldName(data.data.name);
        setOpenUpdate(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em buscar dados do grupo!");
        setShowErrorAlert(true);
      });
  };

  return (
    <>
      <Tooltip title="Adicionar Licença" arrow>
        <IconButton
          color="primary"
          aria-label="add-license-link"
          onClick={() => setOpenAddLicense(true)}
        >
          <AddModeratorOutlinedIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Link para registro de usuário" arrow>
        <IconButton
          color="primary"
          aria-label="register-link"
          onClick={() => setOpenLink(true)}
        >
          <LinkIcon />
        </IconButton>
      </Tooltip>
      <Tooltip color="primary" title="Editar Grupo" arrow>
        <IconButton aria-label="edit" onClick={handleUpdate}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip color="error" title="Apagar Grupo" arrow>
        <IconButton aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <NewGroup
        open={openUpdate}
        setOpen={setOpenUpdate}
        reload={reload}
        type="update"
        id={row.id}
        oldName={oldName}
      />
      <RegisterLink open={openLink} setOpen={setOpenLink} id={row.id} />
      <AddLicense
        open={openAddLicense}
        setOpen={setOpenAddLicense}
        id={row.id}
        reload={reload}
      />
    </>
  );
};

export const ActionsToolbar = (props) => {
  const { ids, reload, setSelected } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    let requests = [];
    ids.forEach((id) => {
      requests.push(deleteGroup({ id: id, token: token }));
    });
    axios
      .all(requests)
      .then(() => {
        reload(true);
        setSelected([]);
        setSuccessAlertMsg("Grupos removidos com sucesso!");
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        reload(true);
        setSelected([]);
        setErrorAlertMsg("Erro em remover os grupos!");
        setShowErrorAlert(true);
      });
  };
  return (
    <IconButton aria-label="delete" onClick={handleDelete}>
      <DeleteIcon sx={{ fill: "#fff" }} />
    </IconButton>
  );
};
