import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import DefaultDialog from "../../../components/Dialog";

import { addUser, updateUser } from "./services";
import { getGroups } from "../../Groups/services";

const default_user = {
  name: "",
  email: "",
  password: "",
  permission: "",
  group_id: null,
  group_name: ""
};

const default_error = {
  name: false,
  email: false,
  password: false,
  permission: false,
  group: false,
};

const NewUser = (props) => {
  const [data, setData] = useState({ ...default_user });
  const [errors, setErrors] = useState({ ...default_error });
  const [emailExist, setEmailExist] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const { open, setOpen, reload, type, id, oldData } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
    PERMISSIONS,
    profile,
  } = useContext(InterfaceContext);

  const checkFields = () => {
    let result = true;
    let errorsMap = {
      email: { email: true },
      name: { name: true },
      permission: { permission: true },
      password: { password: true },
      group: { group: true },
    };
    Object.keys(data).forEach((key) => {
      if (["name", "email", "permission"].includes(key)) {
        if (data[key] === "") {
          setErrors((error) => ({ ...error, ...errorsMap[key] }));
          setLoading(false);
          result = false;
        }
      }
    });
    if (type !== "update" && data.password === "") {
      setErrors((error) => ({ ...error, ...errorsMap.password }));
      setLoading(false);
      result = false;
    }
    if (data.permission !== "super_admin" && data.group_id === null) {
      setErrors((error) => ({ ...error, ...errorsMap.group }));
      setLoading(false);
      result = false;
    }
    return result;
  };

  const posAPIActions = ({ verb, type }) => {
    setLoading(false);
    if (type === "emailError") {
      setErrors((error) => ({ ...error, ...{ email: true } }));
      setEmailExist(true);
    } else {
      reload(true);
      setOpen(false);
      if (type === "success") {
        setSuccessAlertMsg(`Usuário ${verb} com sucesso!`);
        setShowSuccessAlert(true);
      } else {
        setErrorAlertMsg(`Erro ao ${verb} o usuário!`);
        setShowErrorAlert(true);
      }
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (checkFields()) {
      addUser({ data: data, token: token })
        .then((response) => {
          posAPIActions({ verb: "cadastrado", type: "success" });
        })
        .catch((err) => {
          if (err.response.status === 409) {
            posAPIActions({ verb: "", type: "emailError" });
          } else {
            posAPIActions({ verb: "cadastrar", type: "error" });
          }
        });
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    if (checkFields()) {
      updateUser({ id: id, data: data, token: token })
        .then((response) => {
          posAPIActions({ verb: "atualizado", type: "success" });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 409) {
            posAPIActions({ verb: "", type: "emailError" });
          } else {
            posAPIActions({ verb: "atualizar", type: "error" });
          }
        });
    }
  };

  useEffect(() => {
    if (open) {
      setErrors((err) => ({ ...err, ...default_error }));
      setEmailExist(false);
      if (type === "update") {
        setData((data) => ({ ...data, ...oldData }));
      } else {
        setData((data) => ({ ...data, ...default_user }));
      }
      getGroups({ filter: null, token: token }).then((response) => {
        response.data.forEach((group) => {
          setGroups((oldGroups) => [
            ...oldGroups,
            { id: group.id, name: group.name },
          ]);
          if (type === "update") {
            if (group.id === oldData.group_id) {
              setData((data) => ({ ...data, ...{ group_name: group.name } }));
            }
          }
        });
      });
    }
  }, [open, type, oldData, token]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={type === "update" ? "Atualizar Usuário" : "Novo Usuário"}
      type={type}
      handleUpdate={handleUpdate}
      handleSubmit={handleSubmit}
      customDisableCheck={
        data.name.length < 3 ||
        (data.password !== undefined && data.password.length < 6)
      }
      loading={loading}
    >
      <>
        <TextField
          autoFocus
          fullWidth
          required
          disabled={loading}
          error={(data.name.length > 0 && data.name.length < 3) || errors.name}
          helperText={
            data.name.length > 0 &&
            data.name.length < 3 &&
            "Nome deve ter mais 3 ou mais caracteres"
          }
          id="name"
          label="Nome"
          margin="dense"
          value={data.name}
          variant="standard"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ name: event.target.value },
            }))
          }
        />
        <TextField
          fullWidth
          required
          disabled={loading}
          error={errors.email}
          helperText={emailExist && "Email já cadastrado!"}
          id="email"
          label="Email"
          margin="dense"
          type="email"
          value={data.email}
          variant="standard"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ email: event.target.value },
            }))
          }
        />
        <TextField
          fullWidth
          required
          disabled={loading}
          error={
            (data.password !== undefined &&
              data.password.length > 0 &&
              data.password.length < 6) ||
            errors.password
          }
          helperText={
            data.password !== undefined &&
            data.password.length > 0 &&
            data.password.length < 6 &&
            "A senha deve ter mais 6 ou mais caracteres"
          }
          id="password"
          label="Senha"
          margin="dense"
          type="password"
          value={data.password}
          variant="standard"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ password: event.target.value },
            }))
          }
        />
        <FormControl variant="standard" sx={{ width: "100%", mt: 2 }}>
          <InputLabel required error={errors.permission} id="select_permission">
            Permissão
          </InputLabel>
          <Select
            disabled={loading}
            error={errors.permission}
            id="permission"
            label="Permissão"
            labelId="select_permission"
            value={data.permission !== null ? data.permission : ""}
            onChange={(event) =>
              setData((data) => ({
                ...data,
                ...{ permission: event.target.value },
              }))
            }
          >
            {Object.values(PERMISSIONS)
              .filter((permission) => {
                return (
                  profile.permission === "super_admin" ||
                  permission.id !== "super_admin"
                );
              })
              .map((permission, key) => (
                <MenuItem key={key} value={permission.id}>
                  {permission.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl variant="standard" sx={{ width: "100%", mt: 2 }}>
          <InputLabel
            required={data.permission !== "super_admin"}
            error={errors.group}
            id="select_group"
          >
            Grupo
          </InputLabel>
          <Select
            disabled={data.permission === "super_admin" || loading}
            error={errors.group}
            id="group"
            label="Grupo"
            labelId="select_group"
            required={data.permission !== "super_admin"}
            value={data.group_name !== null ? data.group_name : ""}
            onChange={(event, obj) =>
              setData((data) => ({
                ...data,
                ...{ group_id: obj.props.id, group_name: obj.props.value },
              }))
            }
          >
            {groups.map((group, key) => (
              <MenuItem key={key} value={group.name} id={group.id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </>
    </DefaultDialog>
  );
};

export default NewUser;
