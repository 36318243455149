import apiClient from "../../../services/api";

export const removeLicense = ({ id, license_id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.delete(`/groups/${id}/licenses/${license_id}`, options);
};


