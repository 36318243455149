import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import { DefaultFilterAdvanced } from "../../../components/Filter/advanced";

import { getLicenses } from "../../Licenses/services";

export const FilterAdvanced = (props) => {
  const { handleClose, anchorEl, filter, setFilter } = props;
  const [licenses, setLicenses] = useState([]);
  const { token } = useContext(StoreContext);
  const { STATUS_SERVER } = useContext(InterfaceContext);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (Boolean(anchorEl)) {
      getLicenses({ filter: { enabled: true }, token: token })
        .then((response) => {
          let result = [];
          response.data.forEach((r) => {
            result.push({
              id: r.id,
              label: `${r.nome_fantasia} - ${r.razao_social_contrato}`,
            });
          });
          setLicenses(
            result.sort((a, b) => {
              if (a.label > b.label) {
                return 1;
              }
              if (a.label < b.label) {
                return -1;
              }
              return 0;
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
      if (searchParams.has("license_id")) {
        setFilter((data) => ({
          ...data,
          ...{ ids_license: searchParams.get("license_id") },
        }));
        setSearchParams({});
      }
    }
  }, [anchorEl, token, searchParams, setSearchParams, setFilter]);

  return (
    <DefaultFilterAdvanced handleClose={handleClose} anchorEl={anchorEl}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          margin="dense"
          id="hostname"
          label="Hostname"
          fullWidth
          variant="standard"
          value={filter.hostname.value !== null ? filter.hostname.value : ""}
          onChange={(event) =>
            setFilter((filter) => ({
              ...filter,
              ...{
                hostname: {
                  value: event.target.value,
                  label: event.target.value,
                },
              },
            }))
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          autoFocus
          id="license"
          options={licenses}
          onChange={(event, license_selected) => {
            handleClose();
            setFilter((filter) => ({
              ...filter,
              ...{
                ids_license: {
                  value: license_selected.id,
                  label: license_selected.label,
                },
              },
            }));
          }}
          required
          renderInput={(params) => (
            <TextField {...params} label="Licença" variant="standard" />
          )}
          sx={{ width: "100%" }}
          value={
            filter.ids_license.value !== null ? filter.ids_license.value : ""
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel id="select_status">Status</InputLabel>
          <Select
            labelId="select_status"
            id="status"
            label="Status"
            value={filter.status.value !== null ? filter.status.value : ""}
            onChange={(event) => {
              handleClose();
              setFilter((filter) => ({
                ...filter,
                ...{
                  status: {
                    value: event.target.value,
                    label: STATUS_SERVER[event.target.value].label,
                  },
                },
              }));
            }}
          >
            {Object.values(STATUS_SERVER).map((permission, key) => (
              <MenuItem key={key} value={permission.id}>
                {permission.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel id="select_hosted">Hospedagem</InputLabel>
          <Select
            labelId="select_hosted"
            id="hosted"
            label="Hospedagem"
            value={filter.hosted.value !== null ? filter.hosted.vale : ""}
            onChange={(event) => {
              handleClose();
              setFilter((filter) => ({
                ...filter,
                ...{
                  hosted: {
                    value: event.target.value,
                    label: event.target.value.toUpperCase(),
                  },
                },
              }));
            }}
          >
            <MenuItem value="local">Local</MenuItem>
            <MenuItem value="nuvem">Nuvem</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={filter.exceededs.value}
              onChange={(event) => {
                handleClose();
                setFilter((filter) => ({
                  ...filter,
                  ...{
                    exceededs: {
                      value: event.target.checked,
                      label: "Limite de Ramais Excedidos",
                    },
                  },
                }));
              }}
            />
          }
          label="Limite de Ramais Excedidos"
        />
      </Grid>
    </DefaultFilterAdvanced>
  );
};
