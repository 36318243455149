import AWS from "aws-sdk";

const {
  REACT_APP_AWS_KEY,
  REACT_APP_AWS_ACCESS_KEY,
  REACT_APP_AWS_BUCKET,
  REACT_APP_AWS_REGION,
} = process.env;

AWS.config.update({
  accessKeyId: REACT_APP_AWS_KEY,
  secretAccessKey: REACT_APP_AWS_ACCESS_KEY,
});

export const bucketLogo = new AWS.S3({
  params: { Bucket: REACT_APP_AWS_BUCKET },
  region: REACT_APP_AWS_REGION,
});

export const uploadS3Params = {
  ACL: 'public-read',
  Body: null,
  Bucket: REACT_APP_AWS_BUCKET,
  Key: null
};
