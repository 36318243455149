import React, { useContext, useState, useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Button,
  ButtonGroup,
  Chip,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import AddModeratorOutlinedIcon from "@mui/icons-material/AddModeratorOutlined";

import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import { FilterAdvanced } from "./advanced";

const filter_map = {
  cnpj: (rows, filter) => {
    return rows.filter((row) =>
      row.cnpj_contrato.includes(filter) ||
      row.cnpj_faturamento.includes(filter)
        ? true
        : false
    );
  },
  razao_social: (rows, filter) => {
    return rows.filter((row) =>
      row.razao_social_contrato.includes(filter.toUpperCase()) ||
      row.razao_social_faturamento.includes(filter.toUpperCase())
        ? true
        : false
    );
  },
  nome_fantasia: (rows, filter) => {
    return rows.filter((row) =>
      row.nome_fantasia.includes(filter.toUpperCase()) ? true : false
    );
  },
  client_type: (rows, filter) => {
    return rows.filter((row) => (row.client_type === filter ? true : false));
  },
  status_financial: (rows, filter) => {
    return rows.filter((row) =>
      row.status_financial === filter ? true : false
    );
  },
  exceededs: (rows, filter) => {
    if (filter === true) {
      return rows.filter((row) =>
        row.total_extensions > row.max_extensions ? true : false
      );
    } else {
      return rows;
    }
  },
  enabled: (rows, filter) => {
    return rows.filter((row) => (row.enabled === filter ? true : false));
  },
  max_extensions: (rows, filter) => {
    return rows;
  },
  quick: (rows, filter) => {
    return rows.filter((row) =>
      row.cnpj_contrato.includes(filter) ||
      row.cnpj_faturamento.includes(filter) ||
      row.razao_social_contrato.includes(filter.toUpperCase()) ||
      row.razao_social_faturamento.includes(filter.toUpperCase()) ||
      row.nome_fantasia.includes(filter.toUpperCase())
        ? true
        : false
    );
  },
};

const filters = (filter, value, rows) => {
  if (value !== null) {
    let f_filter = filter_map[filter];
    if (f_filter !== undefined) {
      return f_filter(rows, value);
    } else {
      return rows;
    }
  } else {
    return rows;
  }
};

export const Filter = (props) => {
  const [filter, setFilter] = useState({
    cnpj: null,
    razao_social: null,
    nome_fantasia: null,
    client_type: null,
    status_financial: null,
    exceededs: false,
    enabled: true,
    max_extensions: null,
    quick: null,
  });
  const [disableQuick, setDisableQuick] = useState(false);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { rows, setRows, setOpenNewLicense } = props;
  const { CLIENT_TYPE, STATUS_FINANCIAL, profile } =
    useContext(InterfaceContext);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const input = useRef(null);
  const chips = useRef([]);
  const [maxWidthChips, setMaxWidthChips] = useState(170);

  const handleFilter = (event) => {
    let value = event.target.value.length > 0 ? event.target.value : null;
    setFilter((filter) => ({ ...filter, ...{ quick: value } }));
  };

  useEffect(() => {
    let rows_filtered = rows;
    Object.keys(filter).forEach((f) => {
      rows_filtered = filters(f, filter[f], rows_filtered);
    });
    setRows(rows_filtered);
  }, [filter, rows, setRows]);

  useEffect(() => {
    setDisableQuick(false);
    Object.keys(filter).forEach((f) => {
      if (
        f !== "quick" &&
        f !== "exceededs" &&
        f !== "enabled" &&
        filter[f] !== null
      ) {
        setDisableQuick(true);
      } else if (f === "exceededs" && filter[f] === true) {
        setDisableQuick(true);
      } else if (f === "enabled" && filter[f] === false) {
        setDisableQuick(true);
      }
    });
  }, [filter]);

  useEffect(() => {
    let sum = 0;
    let chipsArray = chips.current.filter((n) => n !== null);
    chipsArray.forEach((c) => {
      sum += c.clientWidth;
    });
    if (input.current && sum >= 300) {
      setMaxWidthChips(input.current.offsetWidth / chipsArray.length - 20);
    } else {
      setMaxWidthChips(170);
    }
  }, [filter]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
      sx={{ paddingTop: 1.5, paddingBottom: 1.5 }}
    >
      <Grid item>
        <Typography variant="h6" id="tableTitle" component="div">
          {"licenças".toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6.5}>
        <Input
          fullWidth
          ref={input}
          disabled={disableQuick}
          id="filled-adornment-weight"
          placeholder={disableQuick ? "" : "Filtrar licenças"}
          value={filter.quick !== null ? filter.quick : ""}
          onChange={handleFilter}
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                aria-label="filter"
                onClick={(event) => setShowAdvanced(event.currentTarget)}
              >
                <TuneOutlinedIcon />
              </IconButton>
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ maxWidth: isXs ? 45 : maxWidthChips }}
              >
                {filter.enabled !== true && (
                  <Chip
                    id="1"
                    label="Desabilitado"
                    size="small"
                    variant="outlined"
                    onDelete={() => {
                      setFilter((filter) => ({
                        ...filter,
                        ...{ enabled: true },
                      }));
                    }}
                    ref={(el) => (chips.current[0] = el)}
                  />
                )}
                {filter.exceededs === true && (
                  <Chip
                    label="Limite Ramais Excedidos"
                    size="small"
                    variant="outlined"
                    onDelete={() =>
                      setFilter((filter) => ({
                        ...filter,
                        ...{ exceededs: false },
                      }))
                    }
                    ref={(el) => (chips.current[1] = el)}
                  />
                )}
                {filter.cnpj !== null && (
                  <Chip
                    label={filter.cnpj}
                    size="small"
                    variant="outlined"
                    onDelete={() =>
                      setFilter((filter) => ({
                        ...filter,
                        ...{ cnpj: null },
                      }))
                    }
                    ref={(el) => (chips.current[2] = el)}
                  />
                )}
                {filter.razao_social !== null && (
                  <Chip
                    label={filter.razao_social}
                    size="small"
                    variant="outlined"
                    onDelete={() =>
                      setFilter((filter) => ({
                        ...filter,
                        ...{ razao_social: null },
                      }))
                    }
                    ref={(el) => (chips.current[3] = el)}
                  />
                )}
                {filter.nome_fantasia !== null && (
                  <Chip
                    label={filter.nome_fantasia}
                    size="small"
                    variant="outlined"
                    onDelete={() =>
                      setFilter((filter) => ({
                        ...filter,
                        ...{ nome_fantasia: null },
                      }))
                    }
                    ref={(el) => (chips.current[4] = el)}
                  />
                )}
                {filter.client_type !== null && (
                  <Chip
                    label={CLIENT_TYPE[filter.client_type].label}
                    size="small"
                    variant="outlined"
                    onDelete={() =>
                      setFilter((filter) => ({
                        ...filter,
                        ...{ client_type: null },
                      }))
                    }
                    ref={(el) => (chips.current[5] = el)}
                  />
                )}
                {filter.status_financial !== null && (
                  <Chip
                    label={STATUS_FINANCIAL[filter.status_financial].label}
                    size="small"
                    variant="outlined"
                    onDelete={() =>
                      setFilter((filter) => ({
                        ...filter,
                        ...{ status_financial: null },
                      }))
                    }
                    ref={(el) => (chips.current[6] = el)}
                  />
                )}
              </Stack>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="filter">
                <SearchOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="filled-weight-helper-text"
          inputProps={{
            "aria-label": "weigActionsht",
          }}
        />
      </Grid>
      <Grid item>
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
        >
          {["super_admin"].includes(profile.permission) && (
            <Button
              variant="contained"
              startIcon={<AddModeratorOutlinedIcon />}
              onClick={() => setOpenNewLicense(true)}
            >
              Nova Licença
            </Button>
          )}
        </ButtonGroup>
      </Grid>
      <FilterAdvanced
        anchorEl={showAdvanced}
        handleClose={() => setShowAdvanced(null)}
        filter={filter}
        setFilter={setFilter}
      />
    </Grid>
  );
};
