import apiClient from "../../services/api";

export const getLicenses = ({ filter, token }) => {
  let options = {
    params: filter,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.get("/licenses/", options);
};

export const deleteLicense = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.delete(`/licenses/${id}`, options);
};

export const updateLicense = ({ id, data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.put(`/licenses/${id}`, data, options);
};
