import apiClient from "../../../services/api";

export const getLicense = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return apiClient.get(`/licenses/${id}`, options);
};

export const updateLogo = ({ id, data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.put(`/licenses/${id}/partner`, data, options);
};
