import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Paper, BottomNavigation, BottomNavigationAction } from "@mui/material";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PolicyIcon from "@mui/icons-material/Policy";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";

const MenuXS = () => {
  const [value, setValue] = useState(0);
  const { profile } = useContext(InterfaceContext);

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 99,
      }}
      elevation={5}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        sx={{ background: "#fff" }}
      >
        <BottomNavigationAction
          label="LICENÇAS"
          icon={<PolicyIcon />}
          component={Link}
          to="/"
        />
        <BottomNavigationAction
          label="SERVIDORES"
          icon={<DesktopWindowsIcon />}
          component={Link}
          to="/"
        />
        {profile.permission === "super_admin" && (
          <BottomNavigationAction
            label="GRUPOS"
            icon={<GroupsIcon />}
            component={Link}
            to="/groups"
          />
        )}
        <BottomNavigationAction
          label="USUÁRIOS"
          icon={<PersonIcon />}
          component={Link}
          to="/users"
        />
      </BottomNavigation>
    </Paper>
  );
};

export default MenuXS;
