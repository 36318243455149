import React from "react";
import DefaultDialog from "../../../components/Dialog";
import DefaultTable from "../../../components/Table";
import { ActionsRow } from "./ActionsRow";
import { ActionsToolbar } from "./ActionsToolbar";

const UserList = (props) => {
  const { open, setOpen, data, reload } = props;

  const ActionsToolBarCustom = (props) => {
    return <ActionsToolbar group_id={data[0].group_id} {...props} />;
  };

  const headCells = [
    {
      id: "name",
      numeric: false,
      label: "Nome",
      actions: null,
      sortable: true,
    },
    {
      id: "email",
      numeric: false,
      label: "Email",
      actions: null,
      sortable: true,
    },
  ];

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={"Usuários no Grupo"}
      loading={false}
      maxWidth={"md"}
    >
      <DefaultTable
        rows={data}
        headCells={headCells}
        tableName="Usuários"
        defaultOrderBy="name"
        ActionsToolbar={ActionsToolBarCustom}
        ActionsRow={ActionsRow}
        reload={reload}
      />
    </DefaultDialog>
  );
};

export default UserList;
