import React, { useContext, useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Button, Stack, Chip, useMediaQuery } from "@mui/material";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";

import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import { FilterAdvanced } from "./Advanced";
import { DefaultFilter, DefaultStartAdornment } from "../../../components/Filter";

import { getServers } from "../services";

const filter_map = {
  hostname: (rows, filter) => {
    return rows.filter((row) => (row.hostname.includes(filter) ? true : false));
  },
  status: (rows, filter) => {
    return rows.filter((row) =>
      row.status_financial === filter || row.status === filter ? true : false
    );
  },
  hosted: (rows, filter) => {
    return rows.filter((row) => (row.hosted === filter ? true : false));
  },
  ids_license: (rows, filter) => {
    return rows.filter((row) => (row.id_license === filter ? true : false));
  },
  exceededs: (rows, filter) => {
    if (filter === true) {
      return rows.filter((row) =>
        row.total_extensions > row.max_extensions ? true : false
      );
    } else {
      return rows;
    }
  },
};

const filters = (filter, value, rows) => {
  if (value !== null) {
    let f_filter = filter_map[filter];
    if (f_filter !== undefined) {
      return f_filter(rows, value);
    } else {
      return rows;
    }
  } else {
    return rows;
  }
};

const StartAdornment = (props) => {
  const [maxWidthChips, setMaxWidthChips] = useState(170);
  const chips = useRef([]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const { filter, setShowAdvanced, setFilter, refInput, disabledCheck } = props;
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  let defaultFilter = {
    hostname: { hostname: { value: null } },
    status: { status: { value: null } },
    hosted: { hosted: { value: null } },
    exceededs: { exceededs: { value: false } },
    ids_license: { ids_license: { value: null } },
  };

  useEffect(() => {
    let sum = 0;
    let chipsArray = chips.current.filter((n) => n !== null);
    chipsArray.forEach((c) => {
      sum += c.clientWidth;
    });
    if (refInput.current && sum >= 300) {
      setMaxWidthChips(refInput.current.offsetWidth / chipsArray.length - 20);
    } else {
      setMaxWidthChips(170);
    }
  }, [filter, refInput]);

  return (
    <DefaultStartAdornment setShowAdvanced={setShowAdvanced}>
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ maxWidth: isXs ? 45 : maxWidthChips }}
      >
        {Object.keys(filter)
          .filter((f) =>
            (f !== "hostname" &&
              filter[f].value !== null &&
              Boolean(filter[f].value) === true) ||
            (f === "hostname" &&
              disabledCheck !== false &&
              filter[f].value !== null &&
              filter[f].value.length > 0)
              ? true
              : false
          )
          .map((f, key) => {
            return (
              <Chip
                key={key}
                label={filter[f].label}
                size="small"
                variant="outlined"
                onDelete={() => {
                  if (f === "ids_license" && searchParams.has("license_id")) {
                    searchParams.delete("license_id");
                    setSearchParams(searchParams);
                  }
                  setFilter((filter) => ({
                    ...filter,
                    ...defaultFilter[f],
                  }));
                }}
                ref={(el) => (chips.current[key] = el)}
              />
            );
          })}
      </Stack>
    </DefaultStartAdornment>
  );
};

const CustomButtom = (props) => {
  const { setOpen } = props;
  const { profile } = useContext(InterfaceContext);
  if (
    ["super_admin", "group_admin", "group_tech"].includes(profile.permission)
  ) {
    return (
      <Button
        variant="contained"
        startIcon={<AddToQueueIcon />}
        onClick={() => setOpen(true)}
      >
        Add Servidor
      </Button>
    );
  } else {
    return <></>;
  }
};

export const Filter = (props) => {
  const [disableQuick, setDisableQuick] = useState(false);
  const [filter, setFilter] = useState({
    ids_license: { value: null, label: null },
    hostname: { value: null, label: null },
    status: { value: null, label: null },
    hosted: { value: null, label: null },
    exceededs: { value: false, label: "Limite de Ramais Excedidos" },
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const { token } = useContext(StoreContext);
  const { rows, setRows, setOpenNewServer } = props;
  const inputRef = useRef(null);
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSetValueDefault = (event) => {
    setFilter((data) => ({
      ...data,
      ...{ hostname: { value: event.target.value, label: event.target.value } },
    }));
  };

  const handleGetServers = () => {
    getServers({ filter: filter, token: token })
      .then((response) => setRows(response.data))
      .catch((err) => {});
  };

  useEffect(() => {
    let rows_filtered = rows;
    Object.keys(filter).forEach((f) => {
      rows_filtered = filters(f, filter[f].value, rows_filtered);
    });
    setRows(rows_filtered);
  }, [filter, rows, setRows]);

  useEffect(() => {
    setDisableQuick(false);
    Object.keys(filter).forEach((f) => {
      if (f !== "hostname") {
        if (
          (f === "exceededs" && filter[f].value === true) ||
          (f !== "exceededs" && filter[f].value !== null)
        ) {
          setDisableQuick(true);
        }
      }
    });
  }, [filter]);

  useEffect(() => {
    if (searchParams.has("license_id") && filter.ids_license.value === null) {
      filter.ids_license.value = searchParams.get("license_id");
      filter.ids_license.label = searchParams.get("license_id");
    }
  }, [filter, searchParams]);

  return (
    <>
      <DefaultFilter
        title="servidores"
        disabledCheck={disableQuick}
        placeholder="Filtrar Servidores"
        value={filter.hostname.value !== null ? filter.hostname.value : ""}
        setValue={handleSetValueDefault}
        handleFilter={handleGetServers}
        refInput={inputRef}
        startAdornment={
          <StartAdornment
            setShowAdvanced={setShowAdvanced}
            filter={filter}
            setFilter={setFilter}
            refInput={inputRef}
            disabledCheck={disableQuick}
          />
        }
        customButtons={<CustomButtom setOpen={setOpenNewServer} />}
      />
      <FilterAdvanced
        anchorEl={showAdvanced}
        handleClose={() => setShowAdvanced(null)}
        filter={filter}
        setFilter={setFilter}
      />
    </>
  );
};
