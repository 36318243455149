import apiClient from "../../services/api";

export const getGroups = ({ filter, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  if (filter) {
    options["params"] = {
      name: filter,
    };
  }
  return apiClient.get("/groups/", options);
};
