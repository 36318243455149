import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Autocomplete,
  Alert
} from "@mui/material";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import DefaultDialog from "../../../components/Dialog";

import { addServer, updateServer } from "./services";
import { getLicenses } from "../../Licenses/services";
import { getLicense } from "../../Licenses/ActionsRow/services";


const { REACT_APP_CUSTOM_DOMAIN } = process.env;

const domain =
  String(REACT_APP_CUSTOM_DOMAIN) === "false"
    ? ".com.br"
    : `.${REACT_APP_CUSTOM_DOMAIN}`;

const defaultValues = {
  id_license: null,
  license: null,
  hostname: null,
  max_extensions: 0,
  on_max_extensions: "notify",
  status_financial: "ok",
  in_cloud: false,
  digital_ocean_type: null,
};

const defaultError = {
  id_license: false,
  hostname: false,
  max_extensions: false,
  on_max_extensions: false,
  status_financial: false,
  in_cloud: false,
  digital_ocean_type: false,
};

const defaultErrorMsg = {
  hostname: "",
  max_extensions: "",
};

const countServers = (servers, cloud_type) => {
  let count = 0;
  servers.forEach((server) => {
    if (server.digital_ocean_type === cloud_type) {
      count += 1;
    }
  });
  return count;
};

const makeHostname = (license, in_cloud) => {
  let nome_fantasia = license.nome_fantasia;
  let server_len = license.servers.length + 1;
  let host_number = server_len.toLocaleString("pt-BR", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
  return `${nome_fantasia
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace("-", "")
    .replace(/ +(?= )/g, "")
    .replace(/ /g, '-')}-${host_number}`;
};

const NewServer = (props) => {
  const [data, setData] = useState({ ...defaultValues });
  const [errors, setErrors] = useState({ ...defaultError });
  const [errorsMsg, setErrorsMsg] = useState({ ...defaultErrorMsg });
  const [loading, setLoading] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [license, setLicense] = useState(null);
  const [cloudEnabled, setCloudEnabled] = useState(false);
  const [cloudTypes, setCloudTypes] = useState([]);
  const [totalAlocada, setTotalAlocada] = useState(0);
  const [totalLivre, setTotalLivre] = useState(0);
  const [maxExtensionAllowed, setMaxExtensionAllowed] = useState("");
  const { open, setOpen, reload, type, id, oldData } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
    ACTION_TYPE,
    STATUS_FINANCIAL,
    profile,
  } = useContext(InterfaceContext);

  let maxExtensionHelpText = "Deixar como 0 (zero) para utilizar licença compartilhada.";
  if (errorsMsg.max_extensions !== "" && errors.max_extensions === true) {
    maxExtensionHelpText = errorsMsg.max_extensions;
  } else if (maxExtensionAllowed !== "" && maxExtensionAllowed >= 0) {
    maxExtensionHelpText += ` Máximo permitido: ${maxExtensionAllowed}`;
  }

  const checkFields = () => {
    let result = true;
    let erroMap = {
      id_license: { id_license: true },
      hostname: { hostname: true },
      on_max_extensions: { on_max_extensions: true },
    };
    Object.keys(data).forEach((d) => {
      if (["id_license", "hostname"].includes(d)) {
        if (data[d] === "") {
          setErrors((error) => ({ ...error, ...erroMap[d] }));
          setLoading(false);
          result = false;
        }
      }
    });
    if (maxExtensionAllowed !== "") {
      if (maxExtensionAllowed <= 0) {
        setErrors((error) => ({ ...error, ...{ max_extensions: true } }));
        setLoading(false);
        result = false;
      } else if (type === "new" && data.max_extensions > maxExtensionAllowed) {
        setErrors((error) => ({ ...error, ...{ max_extensions: true } }));
        setLoading(false);
        result = false;
      } else if (
        type === "update" &&
        data.max_extensions > maxExtensionAllowed &&
        oldData.max_extensions !== data.max_extensions
      ) {
        setErrors((error) => ({ ...error, ...{ max_extensions: true } }));
        setLoading(false);
        result = false;
      }
    }

    return result;
  };

  const posAPIActions = ({ verb, type, code, data }) => {
    setLoading(false);
    if (type === "success") {
      setSuccessAlertMsg(`Servidor ${verb} com sucesso!`);
      setShowSuccessAlert(true);
      reload(true);
      setOpen(false);
    } else {
      let errors_msg_map = {
        already_exists_hostname: {
          hostname: "Já existe um servidor com este hostname",
        },
        hostname_no_contains_nome_fantasia: {
          hostname: "É obrigatório conter nome fantasia no hostname",
        },
        hostname_invalid: {
          hostname: "Hostname é inválido",
        },
        license_exceeded: {
          max_extensions: "Licença atingiu o máximo de ramais",
        },
        server_max_extensions: {
          max_extensions: "Valor maior que o permitido pela licença",
        },
      };
      let errors_map = {
        already_exists_hostname: { hostname: true },
        hostname_no_contains_nome_fantasia: { hostname: true },
        hostname_invalid: { hostname: true },
        license_exceeded: { max_extensions: true },
        server_max_extensions: { max_extensions: true },
      };
      if (
        code === 406 &&
        Object.keys(errors_msg_map).includes(data.detail.error)
      ) {
        setErrorsMsg((msg) => ({
          ...msg,
          ...errors_msg_map[data.detail.error],
        }));
        setErrors((msg) => ({
          ...msg,
          ...errors_map[data.detail.error],
        }));
      } else {
        setErrorAlertMsg(`Erro ao ${verb} o servidor!`);
        setShowErrorAlert(true);
        reload(true);
        setOpen(false);
      }
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    if (checkFields()) {
      addServer({ license_id: data.id_license, data: data, token: token })
        .then((response) => {
          posAPIActions({ verb: "adicionado", type: "success" });
        })
        .catch((err) => {
          posAPIActions({
            verb: "adicionar",
            type: "error",
            code: err.response.status,
            data: err.response.data,
          });
        });
    }
  };

  const handleUpdate = () => {
    setLoading(true);
    if (checkFields()) {
      updateServer({
        license_id: data.id_license,
        server_id: id,
        data: data,
        token: token,
      })
        .then((response) => {
          posAPIActions({ verb: "atualizada", type: "success" });
        })
        .catch((err) => {
          posAPIActions({
            verb: "atualizar",
            type: "error",
            code: err.response.status,
            data: err.response.data,
          });
        });
    }
  };

  useEffect(() => {
    if (open) {
      setErrors((data) => ({ ...data, ...defaultError }));
      setErrorsMsg((data) => ({ ...data, ...defaultErrorMsg }));
      if (type === "update") {
        if (oldData.hostname !== "" && oldData.hostname.includes(domain)) {
          oldData.hostname = oldData.hostname.replace(domain, "");
        }
        setData((data) => ({ ...data, ...oldData }));
      } else {
        setData((data) => ({ ...data, ...defaultValues }));
        setLicense(null);
        setCloudEnabled(false);
        // setMaxExtensionAllowed("");
        getLicenses({ filter: { enabled: true }, token: token })
          .then((response) => {
            let result = [];
            response.data.forEach((r) => {
              if (r.status_financial === STATUS_FINANCIAL.ok.id) {
                result.push({
                  id: r.id,
                  label: `${r.nome_fantasia} - ${r.razao_social_contrato}`,
                });
              }
            });
            setLicenses(
              result.sort((a, b) => {
                if (a.label > b.label) {
                  return 1;
                }
                if (a.label < b.label) {
                  return -1;
                }
                return 0;
              })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [open, type, oldData, token, STATUS_FINANCIAL]);

  useEffect(() => {
    if (data.id_license !== null && license === null) {
      getLicense({ id: data.id_license, token: token }).then((response) => {
        setLicense(response.data);
      });
    }
  }, [data, token, license]);

  useEffect(() => {
    if (license !== null) {
      if (cloudEnabled === false) {
        let cloudMap = {
          max_digital_ocean_s: { id: "small", label: "Pequeno" },
          max_digital_ocean_m: { id: "medium", label: "Médio" },
          max_digital_ocean_l: { id: "large", label: "Grande" },
        };
        Object.keys(license).forEach((d) => {
          if (
            [
              "max_digital_ocean_s",
              "max_digital_ocean_m",
              "max_digital_ocean_l",
            ].includes(d)
          ) {
            if (
              license[d] > 0 &&
              countServers(license.servers, cloudMap[d].id) < license[d]
            ) {
              setCloudEnabled(true);
              setCloudTypes((cloudTypes) => [
                ...cloudTypes,
                { id: cloudMap[d].id, label: cloudMap[d].label },
              ]);
            }
          }
        });
      }
      if (data.hostname === null) {
        setData((data) => ({
          ...data,
          ...{ hostname: makeHostname(license, data.in_cloud) },
        }));
      }
      let total_alocada = 0;
      let total_livre = license.max_extensions - license.total_extensions
      setTotalLivre(total_livre);
      license.servers.forEach(server => {
        total_alocada += server.max_extensions;
      });
      setTotalAlocada(total_alocada);
      if (license.max_extensions === 0) {
        setMaxExtensionAllowed(0);
      } else {
        if (total_alocada >= license.max_extensions || total_livre <= 0) {
          if (type === "update") {
            setMaxExtensionAllowed(oldData.max_extensions);
          } else {
            setMaxExtensionAllowed(0);
          }
        } else {
          let diff_total_alocada = license.max_extensions - total_alocada;
          if (diff_total_alocada <= total_livre) {
            if (type === "update") {
              setMaxExtensionAllowed(diff_total_alocada + oldData.max_extensions);
            } else {
              setMaxExtensionAllowed(diff_total_alocada);
            }
          } else {
            if (type === "update") {
              setMaxExtensionAllowed(total_livre + oldData.max_extensions);
            } else {
              setMaxExtensionAllowed(total_livre);
            }
          }
        }
      }
    }
  }, [data, license, cloudEnabled, oldData, type]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={type === "update" ? "Atualizar Servidor" : "Novo Servidor"}
      loading={loading}
      type={type}
      handleUpdate={handleUpdate}
      handleSubmit={handleSubmit}
      maxWidth={"md"}
      customDisableCheck={maxExtensionAllowed !== "" && maxExtensionAllowed <= 0}
    >
      {type === "new" && (
        <Autocomplete
          autoFocus
          disabled={loading}
          error={errors.id_license ? errors.id_license : undefined}
          id="license"
          options={licenses}
          onChange={(event, license_selected) =>
            setData((data) => ({
              ...data,
              ...{
                id_license: license_selected.id,
                license: license_selected.label,
              },
            }))
          }
          required
          renderInput={(params) => (
            <TextField {...params} label="Licença" variant="standard" />
          )}
          sx={{ width: "100%", mt: 1 }}
          value={data.license}
        />
      )}
      {type === "new" && cloudEnabled && (
        <FormControl variant="standard" sx={{ width: "100%", mt: 1 }}>
          <InputLabel required error={errors.in_cloud} id="select_in_cloud">
            Hospedado
          </InputLabel>
          <Select
            disabled={loading}
            error={errors.in_cloud}
            id="in_cloud"
            label="Hospedado"
            labelId="select_in_cloud"
            onChange={(event) =>
              setData((data) => ({
                ...data,
                ...{
                  in_cloud: event.target.value === "local" ? false : true,
                },
              }))
            }
            value={data.in_cloud === false ? "local" : "nuvem"}
          >
            <MenuItem value="local">Local</MenuItem>
            <MenuItem value="nuvem">Nuvem</MenuItem>
          </Select>
        </FormControl>
      )}
      {type === "new" && cloudEnabled && data.in_cloud && (
        <FormControl variant="standard" sx={{ width: "100%", mt: 1 }}>
          <InputLabel
            required
            error={errors.digital_ocean_type}
            id="select_digital_ocean_type"
          >
            Tipo Servidor
          </InputLabel>
          <Select
            autoFocus
            disabled={loading}
            error={errors.digital_ocean_type}
            id="digital_ocean_type"
            label="Tipo Servidor"
            labelId="select_digital_ocean_type"
            onChange={(event) =>
              setData((data) => ({
                ...data,
                ...{ digital_ocean_type: event.target.value },
              }))
            }
            value={
              data.digital_ocean_type !== null ? data.digital_ocean_type : ""
            }
          >
            {cloudTypes.map((l, key) => (
              <MenuItem key={key} value={l.id}>
                {l.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {type === "update" && profile.permission === "super_admin" && (
        <FormControl variant="standard" sx={{ width: "100%", mt: 1 }}>
          <InputLabel required id="select_status">
            Status
          </InputLabel>
          <Select
            disabled={loading}
            // error={errors.status}
            id="status"
            label="Status"
            labelId="select_status"
            onChange={(event) =>
              setData((data) => ({
                ...data,
                ...{ status_financial: event.target.value },
              }))
            }
            value={data.status_financial !== null ? data.status_financial : ""}
          >
            {Object.values(STATUS_FINANCIAL).map((status, key) => (
              <MenuItem key={key} value={status.id}>
                {status.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <TextField
        disabled={loading}
        error={errors.hostname}
        fullWidth
        helperText={errorsMsg.hostname}
        id="hostname"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{domain}</InputAdornment>
          ),
        }}
        label="Hostname"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{
              hostname: event.target.value
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .replace(/ /g, ''),
            },
          }))
        }
        required
        value={data.hostname !== null ? data.hostname : ""}
        variant="standard"
      />
      <TextField
        disabled={
          loading || (maxExtensionAllowed !== "" && maxExtensionAllowed <= 0)
        }
        error={errors.max_extensions}
        helperText={maxExtensionHelpText}
        id="max_extensions"
        label="Limite de Ramais"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ max_extensions: event.target.value },
          }))
        }
        sx={{ width: "45%", mr: "5%" }}
        type="number"
        value={data.max_extensions !== null ? data.max_extensions : 0}
        variant="standard"
        InputProps={{
          inputProps: {
            max: maxExtensionAllowed !== "" ? parseInt(maxExtensionAllowed) : "",
          },
        }}
      />
      <FormControl variant="standard" sx={{ width: "45%", mt: 1, ml: "5%" }}>
        <InputLabel
          error={errors.on_max_extensions}
          id="select_on_max_extensions"
        >
          Quando atingir o máximo de ramais:
        </InputLabel>
        <Select
          disabled={loading}
          error={errors.on_max_extensions}
          id="on_max_extensions"
          label="Quando Atingir o Máximo de ramais:"
          labelId="select_on_max_extensions"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ on_max_extensions: event.target.value },
            }))
          }
          required
          value={
            data.on_max_extensions !== null ? data.on_max_extensions : "block"
          }
        >
          {Object.values(ACTION_TYPE).map((on_max_extensions, key) => (
            <MenuItem key={key} value={on_max_extensions.id}>
              {on_max_extensions.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {(license != null && totalAlocada >= license.max_extensions) && (
        <Alert variant="outlined" severity="warning">
          Total de ramais alocados atingiu o máximo de ramais da licença!
        </Alert>
      )}
      {(license != null && totalLivre <= 0) && (
        <Alert variant="outlined" severity="warning">
          O total de ramais utilizados atingiu o máximo da licença!
        </Alert>
      )}
    </DefaultDialog>
  );
};

export default NewServer;
