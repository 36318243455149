import { styled } from "@mui/material/styles";
import MuiBox from "@mui/material/Box";

const BoxCustom = styled(MuiBox, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginTop: theme.spacing(10),
  ...(open && {
    [theme.breakpoints.up("sm")]: {
      marginLeft: `calc(${theme.spacing(25)} + 10px)`,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    [theme.breakpoints.up("sm")]: {
      marginLeft: `calc(${theme.spacing(8)} + 10px)`,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10px",
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default BoxCustom;
