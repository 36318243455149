import React, { useContext, useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { Button, Stack, Chip, useMediaQuery } from "@mui/material";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import LinkIcon from "@mui/icons-material/Link";

import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import { FilterAdvanced } from "./Advanced";
import { DefaultFilter, DefaultStartAdornment } from "../../../components/Filter";

import RegisterLink from "../../Groups/Actions/RegisterLink";

import { getUsers } from "../services";

const filter_map = {
  name: (rows, filter) => {
    return rows.filter((row) =>
      row.name.toLowerCase().includes(filter) ? true : false
    );
  },
  email: (rows, filter) => {
    return rows.filter((row) => (row.email.includes(filter) ? true : false));
  },
  permission: (rows, filter) => {
    return rows.filter((row) => (row.permission === filter ? true : false));
  },
  disabled: (rows, filter) => {
    return rows.filter((row) => (row.enabled === !filter ? true : false));
  },
};

const filters = (filter, value, rows) => {
  if (value !== null) {
    let f_filter = filter_map[filter];
    if (f_filter !== undefined) {
      return f_filter(rows, value);
    } else {
      return rows;
    }
  } else {
    return rows;
  }
};

const StartAdornment = (props) => {
  const [maxWidthChips, setMaxWidthChips] = useState(170);
  const chips = useRef([]);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const { filter, setShowAdvanced, setFilter, refInput, disabledCheck } = props;

  let defaultFilter = {
    name: { name: { value: null } },
    email: { email: { value: null } },
    permission: { permission: { value: null } },
    disabled: { disabled: { value: false } },
  };

  useEffect(() => {
    let sum = 0;
    let chipsArray = chips.current.filter((n) => n !== null);
    chipsArray.forEach((c) => {
      sum += c.clientWidth;
    });
    if (refInput.current && sum >= 300) {
      setMaxWidthChips(refInput.current.offsetWidth / chipsArray.length - 20);
    } else {
      setMaxWidthChips(170);
    }
  }, [filter, refInput]);

  return (
    <DefaultStartAdornment setShowAdvanced={setShowAdvanced}>
      <Stack
        direction={"row"}
        spacing={1}
        sx={{ maxWidth: isXs ? 45 : maxWidthChips }}
      >
        {Object.keys(filter)
          .filter((f) =>
            (f !== "name" &&
              filter[f].value !== null &&
              Boolean(filter[f].value) === true) ||
            (f === "name" &&
              disabledCheck !== false &&
              filter[f].value !== null &&
              filter[f].value.length > 0)
              ? true
              : false
          )
          .map((f, key) => {
            return (
              <Chip
                key={key}
                label={filter[f].label}
                size="small"
                variant="outlined"
                onDelete={() => {
                  setFilter((filter) => ({
                    ...filter,
                    ...defaultFilter[f],
                  }));
                }}
                ref={(el) => (chips.current[key] = el)}
              />
            );
          })}
      </Stack>
    </DefaultStartAdornment>
  );
};

const CustomButtom = (props) => {
  const { setOpen, setOpenLink } = props;
  const { profile } = useContext(InterfaceContext);
  if (["super_admin", "group_admin"].includes(profile.permission)) {
    return (
      <>
        <Button
          variant="contained"
          startIcon={<AddToQueueIcon />}
          onClick={() => setOpen(true)}
        >
          Novo Usuário
        </Button>
        {profile.permission === "group_admin" && (
          <Button
            variant="contained"
            endIcon={<LinkIcon />}
            onClick={() => setOpenLink(true)}
          >
            Link de Registro
          </Button>
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export const Filter = (props) => {
  const [disableQuick, setDisableQuick] = useState(false);
  const [filter, setFilter] = useState({
    name: { value: null, label: null },
    email: { value: null, label: null },
    permission: { value: null, label: null },
    disabled: { value: false, label: "Desabilitado" },
  });
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const { token } = useContext(StoreContext);
  const { rows, setRows, setOpenNewUser } = props;
  const { setShowErrorAlert, setErrorAlertMsg, profile } =
    useContext(InterfaceContext);
  const inputRef = useRef(null);

  const handleSetValueDefault = (event) => {
    setFilter((data) => ({
      ...data,
      ...{ name: { value: event.target.value, label: event.target.value } },
    }));
  };

  const handleGetUsers = () => {
    getUsers({ filter: filter, token: token })
      .then((data) => {
        setRows(data.data);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em buscar os usuários!");
        setShowErrorAlert(true);
      });
  };

  useEffect(() => {
    let rows_filtered = rows;
    Object.keys(filter).forEach((f) => {
      rows_filtered = filters(f, filter[f].value, rows_filtered);
    });
    setRows(rows_filtered);
  }, [filter, rows, setRows]);

  useEffect(() => {
    setDisableQuick(false);
    Object.keys(filter).forEach((f) => {
      if (f !== "name") {
        if (
          (f === "disabled" && filter[f].value === true) ||
          (f !== "disabled" && filter[f].value !== null)
        ) {
          setDisableQuick(true);
        }
      }
    });
  }, [filter]);

  return (
    <>
      <DefaultFilter
        title="usuarios"
        disabledCheck={disableQuick}
        placeholder="Filtrar Usuários"
        value={filter.name.value !== null ? filter.name.value : ""}
        setValue={handleSetValueDefault}
        handleFilter={handleGetUsers}
        refInput={inputRef}
        startAdornment={
          <StartAdornment
            setShowAdvanced={setShowAdvanced}
            filter={filter}
            setFilter={setFilter}
            refInput={inputRef}
            disabledCheck={disableQuick}
          />
        }
        customButtons={
          <CustomButtom setOpen={setOpenNewUser} setOpenLink={setOpenLink} />
        }
      />
      <RegisterLink
        open={openLink}
        setOpen={setOpenLink}
        id={profile.group_id}
      />
      <FilterAdvanced
        anchorEl={showAdvanced}
        handleClose={() => setShowAdvanced(null)}
        filter={filter}
        setFilter={setFilter}
      />
    </>
  );
};
