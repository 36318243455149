import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import StoreContext from "../contexts/Store/StoreContext";
import InterfaceContext from "../contexts/Interface/InterfaceContext";

import NavBar from "../components/Navbar";
import Menu from "../components/Menu";
import { AlertSucces, AlertError } from "../components/Alert";

const PrivateRoute = ({ permission, children }) => {
  const { token } = useContext(StoreContext);
  const { profile } = useContext(InterfaceContext);

  document.body.style.backgroundColor = "#fff";

  return token && (!profile.permission || permission.includes(profile.permission)) ? (
    <>
      <CssBaseline />
      <NavBar />
      <Outlet />
      <Menu />
      <AlertSucces />
      <AlertError />
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default PrivateRoute;
