import React, { useState, useContext, useEffect } from "react";
import { DialogContentText } from "@mui/material";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import DefaultDialog from "../../../components/Dialog";

import { getRegisterLink } from "./services";

const RegisterLink = (props) => {
  const [link, setLink] = useState("");
  const [loading, setLoading] = useState(false);
  const { open, setOpen, id } = props;
  const { token } = useContext(StoreContext);
  const { setShowErrorAlert, setErrorAlertMsg } = useContext(InterfaceContext);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getRegisterLink({ id: id, token: token })
        .then((data) => {
          var l = `${window.location.origin}/register/${data.data.register_link}`;
          setLink(l);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setOpen(false);
          setErrorAlertMsg("Erro ao buscar link de registro do grupo!");
          setShowErrorAlert(true);
        });
    }
  }, [
    open,
    id,
    token,
    setLink,
    setLoading,
    setOpen,
    setErrorAlertMsg,
    setShowErrorAlert,
  ]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={"Link para registro de usuários"}
      loading={loading}
    >
      <DialogContentText>
        Link para registro de novos usuário:
        <br />
        <a href={link}>{link}</a>
      </DialogContentText>
    </DefaultDialog>
  );
};

export default RegisterLink;
