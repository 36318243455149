import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import PolicyIcon from '@mui/icons-material/Policy';
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";

const openedMixin = (theme) => ({
  width: `calc(${theme.spacing(25)} + 1px)`,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  [theme.breakpoints.down("sm")]: {
    width: 0,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(${theme.spacing(25)} + 1px)`,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  background: theme.palette.primary.main,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const MenuSM = () => {
  const theme = useTheme();
  const { showMenu, profile, menuSelected } = useContext(InterfaceContext);

  return (
    <Drawer variant="permanent" open={showMenu} theme={theme}>
      <List sx={{ marginTop: 8 }}>
        <ListItemButton component={Link} to="/">
          <ListItemIcon>
            <PolicyIcon sx={{ color: theme.palette.primary.contrastText }} />
          </ListItemIcon>
          <ListItemText
            primary="LICENÇAS"
            primaryTypographyProps={{
              fontWeight: menuSelected === 0 ? "bold" : 400,
            }}
          />
        </ListItemButton>
        <ListItemButton component={Link} to="/servers">
          <ListItemIcon>
            <DesktopWindowsIcon sx={{ color: theme.palette.primary.contrastText }} />
          </ListItemIcon>
          <ListItemText
            primary="SERVIDORES"
            primaryTypographyProps={{
              fontWeight: menuSelected === 3 ? "bold" : 400,
            }}
          />
        </ListItemButton>
        {profile.permission === "super_admin" && (
          <ListItemButton component={Link} to="/groups">
            <ListItemIcon>
              <GroupsIcon sx={{ color: theme.palette.primary.contrastText }} />
            </ListItemIcon>
            <ListItemText
              primary="GRUPOS"
              primaryTypographyProps={{
                fontWeight: menuSelected === 1 ? "bold" : 400,
              }}
            />
          </ListItemButton>
        )}
        <ListItemButton component={Link} to="/users">
          <ListItemIcon>
            <PersonIcon sx={{ color: theme.palette.primary.contrastText }} />
          </ListItemIcon>
          <ListItemText
            primary="USUÁRIOS"
            primaryTypographyProps={{
              fontWeight: menuSelected === 2 ? "bold" : 400,
            }}
          />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default MenuSM;
