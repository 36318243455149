import React from "react";
import {
  Grid,
  Input,
  InputAdornment,
  Typography,
  IconButton,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";

export const DefaultStartAdornment = (props) => {
  const { setShowAdvanced } = props;
  return (
    <InputAdornment position="start">
      <IconButton
        aria-label="filter"
        onClick={(event) => setShowAdvanced(event.currentTarget)}
      >
        <TuneOutlinedIcon />
      </IconButton>
      {props.children}
    </InputAdornment>
  );
};

export const DefaultFilter = (props) => {
  const {
    title,
    disabledCheck,
    placeholder,
    value,
    setValue,
    handleFilter,
    startAdornment,
    customButtons,
    refInput,
  } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
      sx={{ paddingTop: 1.5, paddingBottom: 1.5 }}
    >
      <Grid item>
        <Typography variant="h6" id={title} component="div">
          {title.toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6.5}>
        <Input
          fullWidth
          ref={refInput}
          disabled={disabledCheck}
          id={`filter_${title}`}
          placeholder={disabledCheck ? "" : placeholder}
          value={disabledCheck ? "" : value}
          onChange={setValue}
          startAdornment={startAdornment}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="filter" onClick={handleFilter}>
                <SearchOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="filled-weight-helper-text"
          inputProps={{
            "aria-label": "weigActionsht",
          }}
        />
      </Grid>
      <Grid item>{customButtons}</Grid>
    </Grid>
  );
};
