import apiClient from "../../../services/api";

export const removeUserFromGroup = ({ id, user_id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.delete(`/groups/${id}/users/${user_id}`, options);
};
