import React from "react";
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { visuallyHidden } from "@mui/utils";
import PropTypes from "prop-types";

const DefaultTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    ActionsEnabled,
    checkBoxEnabled
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const theme = useTheme();

  return (
    <TableHead sx={{ backgroundColor: theme.palette.grey[300] }}>
      <TableRow>
        {checkBoxEnabled && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id && headCell.sortable ? order : false
            }
            sx={{ color: theme.palette.primary.dark, fontWeight: "bold" }}
          >
            {headCell.sortable === true ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                sx={{ color: theme.palette.primary.dark, fontWeight: "bold" }}
              >
                {headCell.label.toUpperCase()}
                {orderBy === headCell.id ? (
                  <Box
                    component="span"
                    sx={{
                      ...visuallyHidden,
                      color: theme.palette.primary.dark,
                      fontWeight: "bold",
                    }}
                  >
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label.toUpperCase()
            )}
          </TableCell>
        ))}
        {ActionsEnabled !== false && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
};

DefaultTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default DefaultTableHead;
