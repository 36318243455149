import React, { useEffect, useState, useContext } from "react";
import DefaultDialog from "../../../components/Dialog";
import DefaultTable from "../../../components/Table";
import { ActionsRow } from "./ActionsRow";
import { RamaisView, ChipStatus } from "../../Licenses";
import StoreContext from "../../../contexts/Store/StoreContext";

import { getLicenses } from "../../Licenses/services";

const LicenseList = (props) => {
  const [licenses, setLicenses] = useState([]);
  const [customReload, setCustomReload] = useState(false);
  const { token } = useContext(StoreContext);
  const { open, setOpen, data, reload } = props;

  const headCells = [
    {
      id: "id",
      numeric: false,
      label: "Licença",
      actions: null,
      sortable: true,
    },
    {
      id: "ramais",
      numeric: false,
      label: "Ramais",
      actions: RamaisView,
      sortable: false,
    },
    {
      id: "status_financial",
      numeric: false,
      label: "Status",
      actions: ChipStatus,
      sortable: true,
    },
    {
      id: "nome_fantasia",
      numeric: false,
      label: "Nome Fantasia",
      actions: null,
      sortable: true,
    },
  ];

  useEffect(() => {
    if (open && data.licenses.length > 0) {
      let ids = data.licenses.join();
      getLicenses({ filter: { list_id: ids }, token: token })
        .then((response) => {
          response.data.forEach((r) => {
            r.group_id = data.id;
          });
          setLicenses(response.data);
        })
        .catch((err) => console.log(err));
    }
  }, [open, token, data]);

  useEffect(() => {
    if (customReload) {
      setOpen(false);
      reload(true);
    }
  }, [customReload, reload, setOpen]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={"Licenças no Grupo"}
      loading={false}
      maxWidth={"lg"}
    >
      <DefaultTable
        rows={licenses}
        headCells={headCells}
        tableName="Licenças"
        defaultOrderBy="nome_fantasia"
        ActionsToolbar={false}
        ActionsRow={ActionsRow}
        reload={setCustomReload}
      />
    </DefaultDialog>
  );
};

export default LicenseList;
