import apiClient from "../../../services/api";

export const addUser = ({ data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.post(`/users/`, data, options);
};

export const updateUser = ({ id, data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.put(`/users/${id}`, data, options);
};
