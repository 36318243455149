import React, { useContext, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  CssBaseline,
  TextField,
  Box,
  Typography,
  Container,
  Alert,
  Card,
  CircularProgress,
} from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import StoreContext from "../../contexts/Store/StoreContext";
import logo from "../../assets/logo.png";
import { login } from "./service";

export default function SignIn() {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { setToken } = useContext(StoreContext);
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSM = useMediaQuery(theme.breakpoints.only("sm"));
  const navigate = useNavigate();
  const { state } = useLocation();
  let cardLoginWidth = "35vw";
  if (isXs) {
    cardLoginWidth = "100%";
  } else if (isSM) {
    cardLoginWidth = "50vw";
  }

  useEffect(() => {
    document.body.style.backgroundColor = "#1d365d";
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    let email = data.get("email");
    let password = data.get("password");
    login({ username: email, password: password })
      .then((response) => {
        setToken(response.data.access_token);
        navigate(state?.path || "/");
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: isXs ? "0" : "5vh",
        }}
      >
        <Card
          sx={{
            width: cardLoginWidth,
            padding: "3vh 5vh 3vh 5vh",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: "2px 1px 12px 1px",
          }}
        >
          <img
            src={logo}
            style={{ width: "130px", height: "160px", marginRight: "20px" }}
            alt="logo"
          />
          <Typography component="h1" variant="h5" sx={{ margin: 2 }}>
            LICENÇAS
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1, maxWidth: "400px" }}
          >
            {error && (
              <Alert severity="error">
                Ops! Não foi possível entrar no sistema, confira seu email e
                senha!
              </Alert>
            )}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="ipix-license-email"
              type="email"
              autoFocus
              disabled={loading}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Senha"
              type="password"
              id="password"
              autoComplete="ipix-license-password"
              disabled={loading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 1 }}
            >
              Entrar
            </Button>
          </Box>
        </Card>
        {loading && (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </Box>
    </Container>
  );
}
