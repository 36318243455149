import React, { useState, useEffect, useContext } from "react";
import { FormControl, InputLabel, Select, MenuItem, TextField, Checkbox, FormGroup, FormControlLabel } from "@mui/material";

import InterfaceContext from "../../contexts/Interface/InterfaceContext";

import DefaultDialog from "../Dialog";

const UpdateStatus = ({ open, setOpen, data, reload, apiUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [newStatus, setNewStatus] = useState(null);
  const [emailsNotificacao, setEmailsNotificacao] = useState(data.emails_notificacao);
  const [bloqueioImediato, setBloqueioImediato] = useState(false);

  const {
    setSuccessAlertMsg,
    setShowSuccessAlert,
    setErrorAlertMsg,
    setShowErrorAlert,
    STATUS_FINANCIAL,
  } = useContext(InterfaceContext);

  const posAPIActions = ({ verb, type }) => {
    setLoading(false);
    reload(true);
    setOpen(false);
    if (type === "success") {
      setSuccessAlertMsg(`Status ${verb} com sucesso!`);
      setShowSuccessAlert(true);
    } else {
      setErrorAlertMsg(`Erro ao ${verb} o status!`);
      setShowErrorAlert(true);
    }
  };

  const handlerUpdate = () => {
    setLoading(true);
    apiUpdate({
      rowData: data,
      data: { status_financial: newStatus, emails_notificacao: emailsNotificacao !== null ? emailsNotificacao : "", bloqueio_imediato: bloqueioImediato },
    })
      .then((response) => {
        posAPIActions({ verb: "atualizado", type: "success" });
      })
      .catch((err) => {
        posAPIActions({ verb: "atualizar", type: "error" });
      });
  };

  useEffect(() => {
    if (open) {
      setNewStatus(data.status_financial);
    }
  }, [open, data]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title="Atualizar Status"
      loading={loading}
      type="update"
      handleUpdate={handlerUpdate}
      maxWidth={"sm"}
    >
      <FormControl variant="standard" sx={{ width: "100%", mt: 1 }}>
        <InputLabel required id="select_status">
          Status
        </InputLabel>
        <Select
          disabled={loading}
          id="status"
          label="Status"
          labelId="select_status"
          onChange={(event) => setNewStatus(event.target.value)}
          value={newStatus !== null ? newStatus : ""}
        >
          {Object.values(STATUS_FINANCIAL).map((status, key) => (
            <MenuItem key={key} value={status.id}>
              {status.label}
            </MenuItem>
          ))}
        </Select>
        {newStatus === STATUS_FINANCIAL.blocked.id && (
          <>
            <TextField
              helperText={"Para mais de um e-mail, separar com virgula: teste@gmail.com,teste1@gmail.com"}
              id="emails_notificacao"
              label="E-mails para notificação"
              margin="dense"
              onChange={(event) =>
                setEmailsNotificacao(event.target.value)
              }
              type="text"
              value={emailsNotificacao !== null ? emailsNotificacao : ""}
              variant="standard"
            />
            <FormGroup>
              <FormControlLabel control={<Checkbox onChange={() => setBloqueioImediato(true)}/>} label="Bloqueio Imediato" />
            </FormGroup>
          </>
        )}
      </FormControl>
    </DefaultDialog>
  );
};

export default UpdateStatus;
