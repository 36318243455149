import React, { useContext } from "react";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertSucces = () => {
  const { showSuccessAlert, successAlertMsg, setShowSuccessAlert } =
    useContext(InterfaceContext);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSuccessAlert(false);
  };

  return (
    <Snackbar
      open={showSuccessAlert}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
        {successAlertMsg}
      </Alert>
    </Snackbar>
  );
};

export const AlertError = () => {
  const { showErrorAlert, errorAlertMsg, setShowErrorAlert } =
    useContext(InterfaceContext);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorAlert(false);
  };

  return (
    <Snackbar
      open={showErrorAlert}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
        {errorAlertMsg}
      </Alert>
    </Snackbar>
  );
};
