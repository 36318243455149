import React from "react";
import { useTheme } from "@mui/material/styles";
import { Grid, Popover, useMediaQuery } from "@mui/material";

export const DefaultFilterAdvanced = (props) => {
  const { handleClose, anchorEl } = props;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end"
        spacing={1}
        sx={{ width: isXs ? "90vw" : "45vw", pb: 3, pl: 3, pr: 3 }}
      >
        {props.children}
      </Grid>
    </Popover>
  );
};
