import React, { useContext, useState } from "react";
import {
  Grid,
  Input,
  InputAdornment,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import StoreContext from "../../../contexts/Store/StoreContext";

export const Filter = (props) => {
  const [name, setName] = useState("");
  const { reload } = useContext(StoreContext);
  const { rows, setRows, setOpenNewGroup } = props;

  if (reload) {
    setName("");
  }

  const handleFilter = (type) => (event) => {
    if (type === "btn") {
      setRows(rows);
    } else {
      let value = event.target.value;
      if (value.length > 0) {
        let rowsFiltered = rows.filter((row) =>
          row.name.includes(value) ? true : false
        );
        console.log(rowsFiltered);
        setRows(rowsFiltered);
      }
      setName(value);
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={6}
      sx={{ paddingTop: 1.5, paddingBottom: 1.5 }}
    >
      <Grid item>
        <Typography variant="h6" id="tableTitle" component="div">
          {"Grupos".toUpperCase()}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Input
          fullWidth
          id="filled-adornment-weight"
          placeholder="Filtrar Grupos"
          value={name}
          onChange={handleFilter("input")}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="filter" onClick={handleFilter("btn")}>
                <SearchOutlinedIcon />
              </IconButton>
            </InputAdornment>
          }
          aria-describedby="filled-weight-helper-text"
          inputProps={{
            "aria-label": "weigActionsht",
          }}
        />
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          startIcon={<GroupAddOutlinedIcon />}
          onClick={() => setOpenNewGroup(true)}
        >
          Novo Grupo
        </Button>
      </Grid>
    </Grid>
  );
};
