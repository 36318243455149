import React, { useState, useContext, useEffect } from "react";
import { TextField } from "@mui/material";
import DefaultDialog from "../../components/Dialog";
import StoreContext from "../../contexts/Store/StoreContext";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";
import { updateProfile } from "./services";

const Profile = (props) => {
  const [data, setData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    password: false,
  });
  const [emailExist, setEmailExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const { open, setOpen } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
    profile,
    setUpdateProfile,
  } = useContext(InterfaceContext);

  const checkFields = () => {
    let result = true;
    if (data.name === "") {
      setErrors((error) => ({ ...error, ...{ name: true } }));
      setLoading(false);
      result = false;
    }
    if (data.email === "") {
      setErrors((error) => ({ ...error, ...{ email: true } }));
      setLoading(false);
      result = false;
    }
    return result;
  };

  const handleSubmit = () => {
    setLoading(true);
    if (checkFields()) {
      updateProfile({ data: data, token: token })
        .then((response) => {
          setLoading(false);
          setOpen(false);
          setSuccessAlertMsg("Dados atualizados com sucesso!");
          setShowSuccessAlert(true);
          setUpdateProfile((updateProfile) => !updateProfile);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 409) {
            setErrors((error) => ({ ...error, ...{ email: true } }));
            setEmailExist(true);
          } else {
            setOpen(false);
            setErrorAlertMsg("Erro ao atualizar dados!");
            setShowErrorAlert(true);
          }
        });
    }
  };

  useEffect(() => {
    if (open) {
      setErrors({
        name: false,
        email: false,
        password: false,
      });
      setEmailExist(false);
      setData((data) => ({
        ...data,
        ...{
          name: profile.name,
          email: profile.email,
          password: "",
        },
      }));
    }
  }, [open, token, profile]);
  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={"Atualizar Informações"}
      type={"update"}
      handleUpdate={handleSubmit}
      customDisableCheck={
        data.name.length < 3 ||
        (data.password.length > 0 && data.password.length < 6)
      }
      loading={loading}
    >
      <>
        <TextField
          autoFocus
          fullWidth
          required
          disabled={loading}
          error={(data.name.length > 0 && data.name.length < 3) || errors.name}
          helperText={
            data.name.length > 0 &&
            data.name.length < 3 &&
            "Nome deve ter mais 3 ou mais caracteres"
          }
          id="name"
          label="Nome"
          margin="dense"
          value={data.name}
          variant="standard"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ name: event.target.value },
            }))
          }
        />
        <TextField
          fullWidth
          required
          disabled={loading}
          error={errors.email}
          helperText={emailExist && "Email já cadastrado!"}
          id="email"
          label="Email"
          margin="dense"
          type="email"
          value={data.email}
          variant="standard"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ email: event.target.value },
            }))
          }
        />
        <TextField
          fullWidth
          disabled={loading}
          error={
            (data.password !== undefined &&
              data.password.length > 0 &&
              data.password.length < 6) ||
            errors.password
          }
          helperText={
            data.password !== undefined &&
            data.password.length > 0 &&
            data.password.length < 6 &&
            "A senha deve ter mais 6 ou mais caracteres"
          }
          id="password"
          label="Senha"
          margin="dense"
          type="password"
          value={data.password}
          variant="standard"
          onChange={(event) =>
            setData((data) => ({
              ...data,
              ...{ password: event.target.value },
            }))
          }
        />
      </>
    </DefaultDialog>
  );
};

export default Profile;
