import React, { useState, useContext, useEffect } from "react";
import { TextField } from "@mui/material";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import DefaultDialog from "../../../components/Dialog";

import { newGroupApi, updateGroup } from "./services";

const NewGroup = (props) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const { open, setOpen, reload, type, id, oldName } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const posAPIActions = ({ verb, type }) => {
    setLoading(false);
    reload(true);
    setOpen(false);
    if (type === "success") {
      setSuccessAlertMsg(`Grupo ${verb} com sucesso!`);
      setShowSuccessAlert(true);
    } else {
      setErrorAlertMsg(`Erro ao ${verb} o grupo!`);
      setShowErrorAlert(true);
    }
  };

  const handleSubmit = () => {
    setLoading(true);
    newGroupApi({ name: name, token: token })
      .then((data) => {
        posAPIActions({ verb: "adicionado", type: "success" });
      })
      .catch((err) => {
        posAPIActions({ verb: "criar", type: "error" });
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    updateGroup({ id: id, name: name, token: token })
      .then((data) => {
        posAPIActions({ verb: "atualizado", type: "success" });
      })
      .catch((err) => {
        posAPIActions({ verb: "atualizar", type: "error" });
      });
  };

  useEffect(() => {
    if (type === "update") {
      setName(oldName);
    } else {
      setName("");
    }
  }, [open, type, oldName]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title={type === "update" ? "Atualizar Grupo" : "Novo Grupo"}
      loading={loading}
      type={type}
      handleUpdate={handleUpdate}
      handleSubmit={handleSubmit}
      customDisableCheck={name.length < 3}
    >
      <TextField
        error={name.length > 0 && name.length < 3}
        autoFocus
        margin="dense"
        id="name"
        label="Nome"
        fullWidth
        variant="standard"
        value={name}
        onChange={(event) => setName(event.target.value)}
        disabled={loading}
      />
    </DefaultDialog>
  );
};

export default NewGroup;
