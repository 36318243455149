import React, { useState, useEffect, useContext } from "react";
import { TextField, Button, Box, Paper, Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import NoPhotographyOutlinedIcon from "@mui/icons-material/NoPhotographyOutlined";

import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import DefaultDialog from "../../../components/Dialog";
import { updateLogo } from "./services";
import { bucketLogo, uploadS3Params } from "../../../services/aws";

const defaultData = {
  logotype: "",
  site_url: "",
};

const UpdateLogo = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ ...defaultData });
  const [logoUpload, setLogoUpload] = useState(null);
  const { open, setOpen, oldData, reload } = props;
  const {
    setSuccessAlertMsg,
    setShowSuccessAlert,
    setErrorAlertMsg,
    setShowErrorAlert,
  } = useContext(InterfaceContext);
  const { token } = useContext(StoreContext);
  const theme = useTheme();

  const posAPIActions = ({ verb, type }) => {
    setLoading(false);
    reload(true);
    setOpen(false);
    if (type === "success") {
      setSuccessAlertMsg(`Licença ${verb} com sucesso!`);
      setShowSuccessAlert(true);
    } else {
      setErrorAlertMsg(`Erro ao ${verb} a licença!`);
      setShowErrorAlert(true);
    }
  };

  const handlerUpdate = () => {
    setLoading(true);
    if (logoUpload) {
      let params = { ...uploadS3Params };
      params.Body = logoUpload;
      params.Key = `${oldData.id}.png`;
      bucketLogo.putObject(params).send((err) => {
        if (err) {
          console.log(err);
          setErrorAlertMsg("Erro ao carregar Imagem");
          setShowErrorAlert(true);
        } else {
          setSuccessAlertMsg("Logo carregada com sucesso");
          setShowSuccessAlert(true);
          updateLogo({
            id: oldData.id,
            data: {
              logotype: `https://${params.Bucket}.s3.amazonaws.com/${params.Key}`,
              site_url: data.site_url,
            },
            token: token,
          })
            .then((response) => {
              posAPIActions({ verb: "atualizada", type: "success" });
            })
            .catch((err) => {
              posAPIActions({ verb: "atualizar", type: "error" });
            });
        }
      });
    } else if (data.logotype !== "" || data.site_url !== "") {
      updateLogo({
        id: oldData.id,
        data: {
          logotype: data.logotype,
          site_url: data.site_url,
        },
        token: token,
      })
        .then((response) => {
          posAPIActions({ verb: "atualizada", type: "success" });
        })
        .catch((err) => {
          posAPIActions({ verb: "atualizar", type: "error" });
        });
    }
  };

  useEffect(() => {
    if (open && (oldData.site_url || oldData.logotype)) {
      setData((data) => ({ ...data, ...oldData }));
    }
  }, [open, oldData]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title="Atualizar Licença"
      customDisableCheck={data.logotype === "" && !logoUpload}
      loading={loading}
      type="update"
      handleUpdate={handlerUpdate}
      maxWidth={"sm"}
    >
      <TextField
        disabled={loading}
        fullWidth
        id="site_url"
        label="Endereço do site"
        margin="dense"
        onChange={(event) =>
          setData((data) => ({
            ...data,
            ...{ site_url: event.target.value },
          }))
        }
        value={data.site_url}
        variant="standard"
      />
      <Grid container spacing={2} alignItems="flex-end" sx={{ mt: 1 }}>
        <Grid item>
          <Box
            sx={{
              display: "flex",
              "& > :not(style)": {
                width: 128,
                height: 128,
              },
            }}
          >
            {logoUpload && (
              <img src={URL.createObjectURL(logoUpload)} alt="logo" />
            )}
            {data.logotype && !logoUpload && (
              <img src={data.logotype} alt="logo" />
            )}
            {data.logotype === "" && !logoUpload && (
              <Paper
                variant="outlined"
                sx={{
                  background: theme.palette.grey[300],
                  borderStyle: "dashed",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    pt: 2
                  }}
                >
                  <NoPhotographyOutlinedIcon
                    sx={{
                      fontSize: 40,
                      color: theme.palette.grey[600],
                      mb: 1
                    }}
                  />
                  <Typography variant="caption">MAX: 200px x 200px</Typography>
                  <Typography variant="caption">PNG</Typography>
                </Box>
              </Paper>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Button
            startIcon={<PhotoCamera />}
            variant="contained"
            component="label"
          >
            Carregar Logo
            <input
              hidden
              accept="image/png"
              multiple
              type="file"
              onChange={(event) => setLogoUpload(event.target.files[0])}
            />
          </Button>
        </Grid>
      </Grid>
    </DefaultDialog>
  );
};

export default UpdateLogo;
