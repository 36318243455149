import apiClient from "../../../services/api";

export const deleteServer = ({ license_id, server_id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.delete(
    `/licenses/${license_id}/servers/${server_id}`,
    options
  );
};
