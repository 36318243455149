import apiClient from "../../services/api";

export const updateProfile = ({ data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.put("/profile/", data, options);
};
