import apiClient from "../../../services/api";

export const addLicense = ({ data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.post(`/licenses/`, data, options);
};

export const updateLicense = ({ id, data, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.put(`/licenses/${id}`, data, options);
};
