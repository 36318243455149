import React, { useState, useEffect, useContext } from "react";
import { Autocomplete, TextField } from "@mui/material";

import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import DefaultDialog from "../../../components/Dialog";

import { getLicenses } from "../../Licenses/services";
import { addLicense } from "./services";

const AddLicense = (props) => {
  const [loading, setLoading] = useState(false);
  const [licenses, setLicenses] = useState([]);
  const [license, setLicense] = useState(null);
  const { open, setOpen, id, reload } = props;
  const {
    setSuccessAlertMsg,
    setShowSuccessAlert,
    setErrorAlertMsg,
    setShowErrorAlert,
  } = useContext(InterfaceContext);
  const { token } = useContext(StoreContext);

  const posAPIActions = ({ verb, type }) => {
    setLoading(false);
    setOpen(false);
    reload(true);
    if (type === "success") {
      setSuccessAlertMsg(`Grupo ${verb} com sucesso!`);
      setShowSuccessAlert(true);
    } else {
      setErrorAlertMsg(`Erro ao ${verb} o grupo!`);
      setShowErrorAlert(true);
    }
  };

  const handlerUpdate = () => {
    setLoading(true);
    addLicense({
      id: id,
      license_id: license,
      token: token,
    })
      .then((response) => {
        posAPIActions({ verb: "atualizado", type: "success" });
      })
      .catch((err) => {
        posAPIActions({ verb: "atualizar", type: "error" });
      });
  };

  useEffect(() => {
    if (open) {
      getLicenses({ filter: { enabled: true }, token: token })
        .then((response) => {
          let result = [];
          response.data.forEach((r) => {
            result.push({
              id: r.id,
              label: `${r.nome_fantasia} - ${r.razao_social_contrato}`,
            });
          });
          setLicenses(
            result.sort((a, b) => {
              if (a.label > b.label) {
                return 1;
              }
              if (a.label < b.label) {
                return -1;
              }
              return 0;
            })
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [open, token]);

  return (
    <DefaultDialog
      open={open}
      setOpen={setOpen}
      title="Adicionar Licença ao Grupo"
      loading={loading}
      type="update"
      handleUpdate={handlerUpdate}
      maxWidth={"sm"}
    >
      <Autocomplete
        autoFocus
        disabled={loading}
        id="license"
        options={licenses}
        onChange={(event, license_selected) => setLicense(license_selected.id)}
        required
        renderInput={(params) => (
          <TextField {...params} label="Licença" variant="standard" />
        )}
        sx={{ width: "100%", mt: 1 }}
      />
    </DefaultDialog>
  );
};

export default AddLicense;
