import React, { useContext } from "react";
import axios from "axios";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import { removeUserFromGroup } from "./services";

export const ActionsToolbar = (props) => {
  const { group_id, ids, reload, setSelected } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    let requests = [];
    ids.forEach((user_id) => {
      requests.push(
        removeUserFromGroup({ id: group_id, user_id: user_id, token: token })
      );
    });
    axios
      .all(requests)
      .then(() => {
        reload(true);
        setSelected([]);
        setSuccessAlertMsg("Usuários removidos do grupo com sucesso!");
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        reload(true);
        setSelected([]);
        setErrorAlertMsg("Erro em remover usuários do grupo!");
        setShowErrorAlert(true);
      });
  };
  return (
    <IconButton aria-label="delete" onClick={handleDelete}>
      <DeleteIcon sx={{ fill: "#fff" }} />
    </IconButton>
  );
};
