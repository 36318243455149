import apiClient from "../../../services/api";

export const getUser = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return apiClient.get(`/users/${id}`, options);
};

export const deleteUser = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.delete(`/users/${id}`, options);
};

export const getRegisterLink = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return apiClient.get(`/groups/${id}/link`, options);
};
