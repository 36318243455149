import apiClient from "../../services/api";

export const getProfile = ({ token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.get("/profile/", options);
};
