import React, { useContext, useEffect, useState } from "react";
import { Box, Paper, IconButton, CircularProgress } from "@mui/material";
import { green } from "@mui/material/colors";
import { useTheme } from "@mui/material/styles";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import BoxCustom from "../../components/Box";
import DefaultTable from "../../components/Table";
import StoreContext from "../../contexts/Store/StoreContext";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";
import NewGroup from "./New";
import { ActionsRow, ActionsToolbar } from "./Actions";
import { Filter } from "./Filter";
import UserList from "./UserList";
import LicenseList from "./LicenseList";
import { getGroups } from "./services";

const ShowUsers = (props) => {
  const { data, reload } = props;
  const [openUserList, setOpenUserList] = useState(false);
  data.users.forEach((user) => {
    user["group_id"] = data.id;
  });
  return (
    <>
      <IconButton
        color="primary"
        aria-label="show"
        onClick={() => setOpenUserList(true)}
      >
        <ZoomInIcon />
      </IconButton>
      <UserList
        open={openUserList}
        setOpen={setOpenUserList}
        data={data.users}
        reload={reload}
      />
    </>
  );
};

const ShowLicenses = (props) => {
  const { data, reload } = props;
  const [openLicenseList, setOpenLicenseList] = useState(false);
  return (
    <>
      <IconButton
        color="primary"
        aria-label="show"
        onClick={() => setOpenLicenseList(true)}
      >
        <ZoomInIcon />
      </IconButton>
      <LicenseList
        open={openLicenseList}
        setOpen={setOpenLicenseList}
        data={data}
        reload={reload}
      />
    </>
  );
};

const headCells = [
  {
    id: "name",
    numeric: false,
    label: "Nome",
    actions: null,
    sortable: true,
  },
  {
    id: "users",
    numeric: false,
    label: "Usuários",
    actions: ShowUsers,
    sortable: false,
  },
  {
    id: "licenses",
    numeric: false,
    label: "Licenças",
    actions: ShowLicenses,
    sortable: false,
  },
];

const Groups = () => {
  const [rows, setRows] = useState([]);
  const [rowsFiltered, setRowsFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [needReload, setNeedReload] = useState(true);
  const [openNewGroup, setOpenNewGroup] = useState(false);
  const { token } = useContext(StoreContext);
  const { showMenu, setShowErrorAlert, setErrorAlertMsg, setMenuSelected } =
    useContext(InterfaceContext);
  const theme = useTheme();
  // const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    setMenuSelected(1);
    if (needReload) {
      setLoading(true);
      getGroups({ token: token })
        .then((data) => {
          setRows(data.data);
          setRowsFiltered(data.data);
          setLoading(false);
        })
        .catch((err) => {
          setErrorAlertMsg("Erro em buscar os grupos!");
          setShowErrorAlert(true);
          setLoading(false);
        });
      setNeedReload(false);
    }
  }, [token, needReload, setErrorAlertMsg, setShowErrorAlert, setMenuSelected]);

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
      <BoxCustom theme={theme} open={showMenu}>
        <Box sx={{ width: "100%", marginBottom: 4 }}>
          <Paper
            elevation={2}
            sx={{ padding: 1, marginRight: 1, marginLeft: 1 }}
          >
            <Filter
              rows={rows}
              setRows={setRowsFiltered}
              reload={needReload}
              setOpenNewGroup={setOpenNewGroup}
            />
          </Paper>
        </Box>
        {loading ? (
          <CircularProgress
            size={48}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        ) : (
          <DefaultTable
            rows={rowsFiltered}
            headCells={headCells}
            tableName="Grupos"
            reload={setNeedReload}
            ActionsToolbar={ActionsToolbar}
            ActionsRow={ActionsRow}
            defaultOrderBy="name"
            checkBoxEnabled
          />
        )}
      </BoxCustom>
      <NewGroup
        open={openNewGroup}
        setOpen={setOpenNewGroup}
        reload={setNeedReload}
        type="new"
      />
    </Box>
  );
};

export default Groups;
