import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Select,
  Popover,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Checkbox,
  useMediaQuery,
} from "@mui/material";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

export const FilterAdvanced = (props) => {
  const { handleClose, anchorEl, filter, setFilter } = props;
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const theme = useTheme();
  const { CLIENT_TYPE, STATUS_FINANCIAL } = useContext(InterfaceContext);
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="flex-end"
        spacing={1}
        sx={{ width: isXs ? "90vw" : "45vw", pb: 3, pl: 3, pr: 3 }}
      >
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="cnpj"
            label="CNPJ"
            fullWidth
            variant="standard"
            value={filter.cnpj !== null ? filter.cnpj : ""}
            onChange={(event) =>
              setFilter((filter) => ({
                ...filter,
                ...{ cnpj: event.target.value, quick: null },
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="razao_social"
            label="Razão Social"
            fullWidth
            variant="standard"
            value={filter.razao_social !== null ? filter.razao_social : ""}
            onChange={(event) =>
              setFilter((filter) => ({
                ...filter,
                ...{ razao_social: event.target.value, quick: null },
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            margin="dense"
            id="nome_fantasia"
            label="Nome Fantasia"
            fullWidth
            variant="standard"
            value={filter.nome_fantasia !== null ? filter.nome_fantasia : ""}
            onChange={(event) =>
              setFilter((filter) => ({
                ...filter,
                ...{ nome_fantasia: event.target.value, quick: null },
              }))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="select_client_type">Tipo Cliente</InputLabel>
            <Select
              labelId="select_client_type"
              id="client_type"
              label="client_type"
              value={filter.client_type !== null ? filter.client_type : ""}
              onChange={(event) =>
                setFilter((filter) => ({
                  ...filter,
                  ...{ client_type: event.target.value, quick: null },
                }))
              }
            >
              {Object.values(CLIENT_TYPE).map((client_type, key) => (
                <MenuItem key={key} value={client_type.id}>
                  {client_type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="standard" sx={{ width: "100%" }}>
            <InputLabel id="select_status_financial">
              Status Financeiro
            </InputLabel>
            <Select
              labelId="select_status_financial"
              id="status_financial"
              label="status_financial"
              value={
                filter.status_financial !== null ? filter.status_financial : ""
              }
              onChange={(event) =>
                setFilter((filter) => ({
                  ...filter,
                  ...{ status_financial: event.target.value, quick: null },
                }))
              }
            >
              {Object.values(STATUS_FINANCIAL).map((status_financial, key) => (
                <MenuItem key={key} value={status_financial.id}>
                  {status_financial.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={filter.exceededs}
                onChange={(event) =>
                  setFilter((filter) => ({
                    ...filter,
                    ...{ exceededs: event.target.checked, quick: null },
                  }))
                }
              />
            }
            label="Limite de Ramais Excedidos"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={filter.enabled}
                onChange={(event) =>
                  setFilter((filter) => ({
                    ...filter,
                    ...{ enabled: event.target.checked, quick: null },
                  }))
                }
              />
            }
            label="Habilitado"
          />
        </Grid>
      </Grid>
    </Popover>
  );
};
