import React, { useContext } from "react";

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from "@mui/material";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import { DefaultFilterAdvanced } from "../../../components/Filter/advanced";

export const FilterAdvanced = (props) => {
  const { handleClose, anchorEl, filter, setFilter } = props;
  const { PERMISSIONS } = useContext(InterfaceContext);

  return (
    <DefaultFilterAdvanced handleClose={handleClose} anchorEl={anchorEl}>
      <Grid item xs={12}>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nome"
          fullWidth
          variant="standard"
          value={filter.name.value !== null ? filter.name.value : ""}
          onChange={(event) =>
            setFilter((filter) => ({
              ...filter,
              ...{
                name: {
                  value: event.target.value,
                  label: event.target.value,
                },
              },
            }))
          }
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="dense"
          id="email"
          label="Email"
          fullWidth
          variant="standard"
          value={filter.email.value !== null ? filter.email.value : ""}
          onChange={(event) =>
            setFilter((filter) => ({
              ...filter,
              ...{
                email: {
                  value: event.target.value,
                  label: event.target.value,
                },
              },
            }))
          }
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="standard" sx={{ width: "100%" }}>
          <InputLabel id="select_permission">Permissão</InputLabel>
          <Select
            labelId="select_permission"
            id="permission"
            label="Permissão"
            value={
              filter.permission.value !== null ? filter.permission.value : ""
            }
            onChange={(event) => {
              handleClose();
              setFilter((filter) => ({
                ...filter,
                ...{
                  permission: {
                    value: event.target.value,
                    label: PERMISSIONS[event.target.value].label,
                  },
                },
              }));
            }}
          >
            {Object.values(PERMISSIONS).map((permission, key) => (
              <MenuItem key={key} value={permission.id}>
                {permission.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              defaultChecked={filter.disabled.value}
              onChange={(event) => {
                handleClose();
                setFilter((filter) => ({
                  ...filter,
                  ...{
                    disabled: {
                      value: event.target.checked,
                      label: "Desabilitado",
                    },
                  },
                }));
              }}
            />
          }
          label="Desabilitado"
        />
      </Grid>
    </DefaultFilterAdvanced>
  );
};
