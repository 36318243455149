import React from "react";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import MenuSM from "./MenuSM";
import MenuXS from "./MenuXS";

const Menu = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  let retorno; 
  if (matches) {
    retorno = <MenuSM />
  } else {
    retorno = <MenuXS />
  }
  return retorno
}

export default Menu;
