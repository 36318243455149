import React, { useState, useContext, useEffect } from "react";
import Chip from "@mui/material/Chip";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

import InterfaceContext from "./InterfaceContext";
import StoreContext from "../Store/StoreContext";
import { getProfile } from "./service";
const PERMISSIONS = {
  super_admin: {
    id: "super_admin",
    label: "Administrador",
    chip: <Chip label="Administrador" color="success" />,
  },
  group_admin: {
    id: "group_admin",
    label: "Administrador de Grupo",
    chip: <Chip label="Administrador de Grupo" color="primary" />,
  },
  group_tech: {
    id: "group_tech",
    label: "Técnico",
    chip: <Chip label="Técnico" color="warning" />,
  },
  group_view: {
    id: "group_view",
    label: "Visualizador",
    chip: <Chip label="Visualizador" color="info" />,
  },
};

const STATUS_FINANCIAL = {
  ok: {
    id: "ok",
    label: "OK",
    help: "Sem pendências financeiras",
    chip: (
      <Chip
        icon={<AttachMoneyOutlinedIcon fontSize="small"/>}
        label="OK"
        color="success"
        sx={{ m: 0.25 }}
      />
    ),
  },
  blocked: {
    id: "blocked",
    label: "Bloqueado",
    help: "Existem pendências financeiras",
    chip: (
      <Chip
        icon={<AttachMoneyOutlinedIcon fontSize="small"/>}
        label="BLOQUEADO"
        color="error"
        sx={{ m: 0.25 }}
      />
    ),
  },
  inactived: {
    id: "inactived",
    label: "Inativo",
    help: "Sem checagem de status por mais de 30 dias",
    chip: (
      <Chip
        icon={<AttachMoneyOutlinedIcon fontSize="small"/>}
        label="INATIVO"
        color="warning"
        sx={{ m: 0.25 }}
      />
    ),
  },
};

const STATUS_SERVER = {
  ...STATUS_FINANCIAL,
  running: {
    id: "running",
    label: "Online",
    help: "Servidor Online",
    chip: (
      <Chip
        icon={<DesktopWindowsIcon fontSize="small"/>}
        label="ONLINE"
        color="success"
        sx={{ m: 0.25 }}
      />
    ),
  },
  inactive: {
    id: "inactive",
    label: "Inoperante",
    help: "Sem checagem de status por mais de 30 dias",
    chip: (
      <Chip
        icon={<DesktopWindowsIcon fontSize="small"/>}
        label="INOPERANTE"
        color="error"
        sx={{ m: 0.25}}
      />
    ),
  },
  pending: {
    id: "pending",
    label: "Pendente",
    help: "Servidor em provisionamento",
    chip: (
      <Chip
        icon={<DesktopWindowsIcon fontSize="small"/>}
        label="PENDENTE"
        color="warning"
        sx={{ m: 0.25 }}
      />
    ),
  },
  availabe: {
    id: "availabe",
    label: "Disponível",
    help: "Servidor disponível para instalação",
    chip: (
      <Chip
        icon={<DesktopWindowsIcon fontSize="small"/>}
        label="DISPONÍVEL"
        color="info"
        sx={{ m: 0.25 }}
      />
    ),
  },
};

const CLIENT_TYPE = {
  partner: {
    id: "partner",
    label: "Parceiro",
    chip: <Chip label="PARCEIRO" color="secondary" />,
  },
  end: {
    id: "end",
    label: "Final",
    chip: <Chip label="FINAL" color="primary" />,
  },
};

const ACTION_TYPE = {
  notify: {
    id: "notify",
    label: "Notificar",
  },
  block: {
    id: "block",
    label: "Bloquear",
  },
};

const InterfaceProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(true);
  const [menuSelected, setMenuSelected] = useState(0);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMsg, setSuccessAlertMsg] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [errorAlertMsg, setErrorAlertMsg] = useState("");
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    permission: null,
    group_id: null,
  });
  const [updateProfile, setUpdateProfile] = useState(false);
  const { token } = useContext(StoreContext);

  useEffect(() => {
    if (token && profile.permission === null) {
      getProfile({ token: token })
        .then((response) => {
          setProfile((data) => ({ ...data, ...response.data }));
        })
        .catch((err) => console.log(err));
    }
  }, [token, updateProfile, profile]);

  return (
    <InterfaceContext.Provider
      value={{
        showMenu,
        setShowMenu,
        showSuccessAlert,
        setShowSuccessAlert,
        successAlertMsg,
        setSuccessAlertMsg,
        showErrorAlert,
        setShowErrorAlert,
        errorAlertMsg,
        setErrorAlertMsg,
        PERMISSIONS,
        profile,
        setProfile,
        setUpdateProfile,
        menuSelected,
        setMenuSelected,
        STATUS_FINANCIAL,
        CLIENT_TYPE,
        ACTION_TYPE,
        STATUS_SERVER,
      }}
    >
      {children}
    </InterfaceContext.Provider>
  );
};

export default InterfaceProvider;
