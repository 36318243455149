import React, { useContext } from "react";
import axios from "axios";
import { IconButton, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import DeleteIcon from "@mui/icons-material/Delete";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import HideSourceIcon from "@mui/icons-material/HideSource";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";

import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";

import { deleteLicense, updateLicense } from "../services";

export const ActionsToolbar = (props) => {
  const { ids, reload, setSelected } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);
  const theme = useTheme();

  const handlerStatus = (status) => {
    let requests = [];
    ids.forEach((id) => {
      requests.push(
        updateLicense({
          id: id,
          data: { status_financial: status },
          token: token,
        })
      );
    });
    axios
      .all(requests)
      .then(() => {
        reload(true);
        setSelected([]);
        setSuccessAlertMsg("Licenças atualizadas com sucesso!");
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        reload(true);
        setSelected([]);
        setErrorAlertMsg("Erro em remover as licenças!");
        setShowErrorAlert(true);
      });
  };

  const handleDelete = () => {
    let requests = [];
    ids.forEach((id) => {
      requests.push(deleteLicense({ id: id, token: token }));
    });
    axios
      .all(requests)
      .then(() => {
        reload(true);
        setSelected([]);
        setSuccessAlertMsg("Licenças removidas com sucesso!");
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        reload(true);
        setSelected([]);
        setErrorAlertMsg("Erro em remover as licenças!");
        setShowErrorAlert(true);
      });
  };
  return (
    <>
      <Tooltip
        title="Alterar Status para: Inativo"
        arrow
        onClick={() => handlerStatus("inactived")}
      >
        <IconButton aria-label="status_inactive">
          <HideSourceIcon sx={{ fill: theme.palette.warning.main }} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Alterar Status para: Bloqueado"
        arrow
        onClick={() => handlerStatus("blocked")}
      >
        <IconButton aria-label="status_block">
          <MoneyOffIcon sx={{ fill: theme.palette.error.light }} />
        </IconButton>
      </Tooltip>
      <Tooltip
        title="Alterar Status para: Ok"
        arrow
        onClick={() => handlerStatus("ok")}
      >
        <IconButton aria-label="status_ok">
          <AttachMoneyOutlinedIcon sx={{ fill: theme.palette.success.light }} />
        </IconButton>
      </Tooltip>
      <IconButton aria-label="delete" onClick={handleDelete}>
        <DeleteIcon sx={{ fill: theme.palette.common.white }} />
      </IconButton>
    </>
  );
};
