import React, { useContext, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Toolbar,
  IconButton,
  Badge,
  Avatar,
  Typography,
} from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InterfaceContext from "../../contexts/Interface/InterfaceContext";
import MenuNotify from "./MenuNotify";
import MenuUser from "./MenuUser";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  background: theme.palette.primary.contrastText,
  boxShadow: "1px 1px 10px -2px rgba(0, 0, 0, 0.4)",
  color: theme.palette.primary.light,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const NavBar = () => {
  const [anchorNotificao, setAnchorNotificao] = useState(null);
  const [anchorUser, setAnchorUser] = useState(null);
  const theme = useTheme();
  const { showMenu, setShowMenu, profile } = useContext(InterfaceContext);

  const handleClickNotificao = (event) => {
    setAnchorNotificao(event.currentTarget);
  };

  const handleCloseNotificao = () => {
    setAnchorNotificao(null);
  };

  const handleClickUser = (event) => {
    setAnchorUser(event.currentTarget);
  };

  const handleCloseUser = () => {
    setAnchorUser(null);
  };
  const handleDrawerOpen = () => {
    setShowMenu(!showMenu);
  };

  return (
    <AppBar position="fixed" open={showMenu} theme={theme}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="open menu"
          sx={{ mr: 2, display: { xs: "none", sm: "block" } }}
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ mb: 1 }}>
          <Typography component="h1" variant="h5">
            LICENÇAS
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <Box>
          <IconButton
            size="large"
            color="primary"
            onClick={handleClickNotificao}
          >
            <Badge badgeContent={0} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <MenuNotify
            anchorEl={anchorNotificao}
            handleClose={handleCloseNotificao}
          />
        </Box>

        <Box>
          <IconButton
            size="large"
            edge="end"
            aria-label="actions of user"
            aria-haspopup="true"
            onClick={handleClickUser}
            color="primary"
          >
            <Avatar sx={{ bgcolor: theme.palette.primary.main }}>
              <AccountCircle />
            </Avatar>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", marginLeft:1 }}>
              {profile.name.split(" ")[0]}
            </Typography>
          </IconButton>
          <MenuUser anchorEl={anchorUser} handleClose={handleCloseUser} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
