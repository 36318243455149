import React, { useContext, useState } from "react";
import axios from "axios";
import { IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import NewUser from "../New";
import RegisterLink from "./RegisterLink";
import { deleteUser, getUser } from "./services";

export const ActionsRow = (props) => {
  const { row, reload } = props;
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const [oldData, setOldData] = useState({
    name: "",
    email: "",
    password: "",
    permission: "",
    group_id: null,
  });
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    deleteUser({ id: row.id, token: token })
      .then((data) => {
        setSuccessAlertMsg("Usuário removido com sucesso!");
        setShowSuccessAlert(true);
        reload(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em remover o usuário!");
        setShowErrorAlert(true);
      });
  };

  const handleUpdate = () => {
    getUser({ id: row.id, token: token })
      .then((data) => {
        setOldData((olddata) => ({
          ...olddata,
          ...{
            name: data.data.name,
            email: data.data.email,
            password: data.data.password,
            permission: data.data.permission,
            group_id: data.data.group_id,
          },
        }));
        setOpenUpdate(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em buscar dados do usuário!");
        setShowErrorAlert(true);
      });
  };
  return (
    <>
      <Tooltip title="Editar Usuário" arrow onClick={handleUpdate}>
        <IconButton color="primary" aria-label="edit">
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Apagar Usuário" arrow>
        <IconButton color="error" aria-label="delete" onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
      <NewUser
        open={openUpdate}
        setOpen={setOpenUpdate}
        reload={reload}
        type="update"
        id={row.id}
        oldData={oldData}
      />
      <RegisterLink open={openLink} setOpen={setOpenLink} id={row.id} />
    </>
  );
};

export const ActionsToolbar = (props) => {
  const { ids, reload, setSelected } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    let requests = [];
    ids.forEach((id) => {
      requests.push(deleteUser({ id: id, token: token }));
    });
    axios
      .all(requests)
      .then(() => {
        reload(true);
        setSelected([]);
        setSuccessAlertMsg("Usuários removidos com sucesso!");
        setShowSuccessAlert(true);
      })
      .catch((errors) => {
        reload(true);
        setSelected([]);
        setErrorAlertMsg("Erro em remover os usuários!");
        setShowErrorAlert(true);
      });
  };
  return (
    <IconButton aria-label="delete" onClick={handleDelete}>
      <DeleteIcon sx={{ fill: "#fff" }} />
    </IconButton>
  );
};
