import React from "react";
import { useTheme } from "@mui/material/styles";
import { Toolbar, Typography } from "@mui/material";
import PropTypes from "prop-types";

const DefaultTableToolbar = (props) => {
  const { selected, Actions, reload, setSelected } = props;
  const theme = useTheme();

  return (
    <Toolbar
      sx={{
        bgcolor: theme.palette.primary.light,
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        color="#fff"
        variant="subtitle1"
        component="div"
      >
        {selected.length} selecionado(s)
      </Typography>
      <Actions ids={selected} reload={reload} setSelected={setSelected} />
    </Toolbar>
  );
};

DefaultTableToolbar.propTypes = {
  selected: PropTypes.array.isRequired,
  Actions: PropTypes.func.isRequired,
  reload: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
};

export default DefaultTableToolbar;
