import React, { useContext } from "react";
import { Tooltip, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StoreContext from "../../../contexts/Store/StoreContext";
import InterfaceContext from "../../../contexts/Interface/InterfaceContext";
import { removeUserFromGroup } from "./services";

export const ActionsRow = (props) => {
  const { row, reload } = props;
  const { token } = useContext(StoreContext);
  const {
    setShowSuccessAlert,
    setSuccessAlertMsg,
    setShowErrorAlert,
    setErrorAlertMsg,
  } = useContext(InterfaceContext);

  const handleDelete = () => {
    removeUserFromGroup({ id: row.group_id, user_id: row.id, token: token })
      .then((data) => {
        setSuccessAlertMsg("Usuário removido do grupo com sucesso!");
        setShowSuccessAlert(true);
        reload(true);
      })
      .catch((err) => {
        setErrorAlertMsg("Erro em remover usuário do grupo!");
        setShowErrorAlert(true);
      });
  };

  return (
    <Tooltip title="Remover usuário" arrow>
      <IconButton aria-label="delete" onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};
