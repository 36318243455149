import apiClient from "../../../services/api";

export const getGroup = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
 
  return apiClient.get(`/groups/${id}`, options);
};

export const deleteGroup = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return apiClient.delete(`/groups/${id}`, options);
};

export const getRegisterLink = ({ id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
 
  return apiClient.get(`/groups/${id}/link`, options);
};

export const addLicense = ({ id, license_id, token }) => {
  let options = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let data = {
    license_id: license_id,
  };
  return apiClient.post(`/groups/${id}/licenses`, data, options);
};
